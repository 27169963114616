<template>
  <h1 class="title is-family-monospace mx-6 my-5">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li><a href="/">Record Club</a></li>
        <li><a href="/collections">Collections</a></li>
        <li class="is-active">
          <a href="#" aria-current="page">Vaporwave</a>
        </li>
      </ul>
    </nav>
  </h1>
  <div class="container columns mx-6 my-5">
    <div class="column is-full">
      <div class="bottom-space">
        <div class="is-family-monospace">
          <div class="box container columns">
            <div class="column is-one-half">
              <div class="content">
                <h4>Release #1. Bezos Fantagram.</h4>
                <b>100 Editions. Available RECORD STORE DAY 2022.</b>
              </div>
              <div style="flex-wrap: wrap" class="tile is-ancestor">
                <div
                  class="tile px-2 py-3"
                  v-for="image in images"
                  :key="image.src"
                >
                  <div class="box">
                    <figure class="image is-96x96">
                      <img v-bind:src="image.src" />
                    </figure>
                  </div>
                </div>
              </div>
              <div class="block"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
export default {
  data() {
    return {
      images: [
        { src: "images/bezos/bezos_1.png" },
        { src: "images/bezos/bezos_2.png" },
        { src: "images/bezos/bezos_3.png" },
        { src: "images/bezos/bezos_4.png" },
        { src: "images/bezos/bezos_5.png" },
        { src: "images/bezos/bezos_6.png" },
        { src: "images/bezos/bezos_7.png" },
        { src: "images/bezos/bezos_8.png" },
        { src: "images/bezos/bezos_9.png" },
        { src: "images/bezos/bezos_10.png" },
        { src: "images/bezos/bezos_11.png" },
        { src: "images/bezos/bezos_12.png" },
      ],
    };
  },
  name: "Vaporwave",
  setup() {},
  methods: {},
};
</script>
<style scoped>
</style>
