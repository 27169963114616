<template>
  <o-button id="hide-seen" @click="connectWalletConnect">{{ buttonText }}</o-button>
</template>
<script>
import { ref } from "vue";
import { store } from "../store";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import Web3 from "web3";

import NodeWalletConnect from "@walletconnect/node";
import WalletConnectQRCodeModal from "@walletconnect/qrcode-modal";



export default {
  name: "Metamask",
  data() {
    return {
      provider: null
    }
  },
  setup() {
    const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
    let buttonText = ref("Connect Wallet");

    return {
      buttonText,
    };
  },
  mounted() {
    if (store.getters.walletId.length > 0) {
      this.buttonText = "Connected";
    }
  },
  methods: {

    async connectWalletConnect() {

            // Create connector
      const walletConnector = new NodeWalletConnect(
        {
          bridge: "https://bridge.walletconnect.org", // Required
        },
        {
          clientMeta: {
            description: "WalletConnect NodeJS Client",
            url: "https://nodejs.org/en/",
            icons: ["https://nodejs.org/static/images/logo.svg"],
            name: "WalletConnect",
          },
        }
      );

      // Check if connection is already established
      if (!walletConnector.connected) {
        // create new session
        walletConnector.createSession().then(() => {
          // get uri for QR Code modal
          const uri = walletConnector.uri;
          // display QR Code modal
          WalletConnectQRCodeModal.open(
            uri,
            () => {
              console.log("QR Code Modal closed");
            },
            true // isNode = true
          );
        });
      }

      // Subscribe to connection events
      walletConnector.on("connect", (error, payload) => {
        if (error) {
          throw error;
        }

        // Close QR Code Modal
        WalletConnectQRCodeModal.close(
          true // isNode = true
        );

        // Get provided accounts and chainId
        const { accounts, chainId } = payload.params[0];

        console.log(`accounts ${accounts}`);
        console.log(`chainId ${chainId}`);
        console.log(typeof accounts);
        this.connectWallet(accounts[0]);
      });

      walletConnector.on("session_update", (error, payload) => {
        if (error) {
          throw error;
        }

        // Get updated accounts and chainId
        const { accounts, chainId } = payload.params[0];
 
      });

      walletConnector.on("disconnect", (error, payload) => {
        if (error) {
          throw error;
        }

        // Delete walletConnector
      });

      /*const provider = new WalletConnectProvider({
        infuraId: "16321cc9c7b04a09b215bcbced1b297b",
        qrcodeModalOptions: {
                mobileLinks: [
                    "rainbow",
                    "metamask",
                ],
            },
      });

      await provider.enable();

      /*const providerOptions = {
        walletconnect: {
          package: WalletConnectProvider, // required
          options: {
            network: "rinkeby",
            infuraId: '16321cc9c7b04a09b215bcbced1b297b'
            rpc: {
              1: "https://eth-mainnet.alchemyapi.io/v2/api",
              3: "https://eth-ropsten.alchemyapi.io/v2/api",
              4: "https://eth-rinkeby.alchemyapi.io/v2/NXc3nAVIhz-03JtVYgcMm0NmnWnlLR50",
              127: "https://polygon-mainnet.g.alchemy.com/v2/HrOdn239xaQhtStq3po4k4r2Sfao1oze",
            },
          }
        }
      }

      const web3Modal = new Web3Modal({
        network: "rinkeby", // optional
        cacheProvider: false, // optional
        providerOptions,
        disableInjectedProvider: false // required
      });

      console.log("Web3Modal instance is", web3Modal);

      console.log("Opening a dialog", web3Modal);
      try {
        this.provider = await web3Modal.connect();
      } catch(e) {
        console.log("Could not get a wallet connection", e);
        return;
      }*/

/*
      // Subscribe to accounts change
      provider.on("accountsChanged", (accounts) => {
        console.log(`accountsChanged ${accounts}`);
        this.fetchAccountData();
      });

      // Subscribe to chainId change
      provider.on("chainChanged", (chainId) => {
        console.log(`chainChanged ${chainId}`);
        this.fetchAccountData();
      });

      // Subscribe to networkId change
      provider.on("chainChanged", (networkId) => {
        console.log(`chainChanged ${networkId}`);
        this.fetchAccountData();
      });
*/
      //await fetchAccountData(provider);
    },

    async fetchAccountData() {
      console.log('fetch account data');
    },

    async connectWallet(existingAccount = null) {

      const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
      const web3 = createAlchemyWeb3(
        "https://polygon-mainnet.g.alchemy.com/v2/HrOdn239xaQhtStq3po4k4r2Sfao1oze"
      );

      if (window.ethereum) {
        try {
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          this.$store.commit("updateWallet", accounts[0]);
          console.log(this.$store.state.walletId);
          console.log(`wallet account info: ${accounts[0]}`);
          this.buttonText = "Connected";
        } catch (error) {
          if (error.code === 4001) {
            // User rejected request
          }

          //setError(error);
        }
      } else {
        console.log(`no window.ethereum`);
        this.$store.commit("updateWallet", existingAccount);
          console.log(this.$store.state.walletId);
          console.log(`wallet account info: existingAccount}`);
          this.buttonText = "Connected";
        
      }

      /*web3.eth.getAccounts().then((accounts) => {
        web3.eth.sendTransaction({
          from: accounts[0],
          to: "0x6A823E…",
          value: "1000000000000000000",
        });
      });*/
    },
  },
};
</script>
<style scoped>
</style>