let p5;
const ImageSlice = require('./imageSlice');
const VaporwaveColors = require('./vaporwaveColors');
var imageSlices = [];
const brickWidth = 20;
const brickHeight = 2;
let img;
let loading = true;
let angle;
let colors;
let loadingColor;
let playing = false;
var canvas;
let canvasWidth = document.getElementById('p5Canvas').offsetWidth;
let metadata;

export function setData(data) {
    metadata = data.properties.properties.art;
}

export function main(_p5) {

    p5 = _p5;
    loading = true;
    angle = 0;
    colors = new VaporwaveColors(p5);
    loadingColor = colors.randomFrom();
    //canvas;

    p5.preload = _ => {

        /*let imgs = ['img.jpg', 'img2.jpg', 'img3.jpg', 'img4.jpg', 'img5.jpg'];
        let rnmImg = imgs[p5.floor(p5.random(imgs.length))];
        img = p5.loadImage(require(`@/assets/images/${rnmImg}`), imageLoaded);*/
    }

    function imageLoaded(img) {
        console.log("loaded!");
        p5.image(img, -canvasWidth / 2, -canvasWidth / 2);

        for (var x = 0; x < p5.width; x += brickWidth) {
            for (var y = 0; y < p5.height; y += brickHeight) {
                let offset = 0;
                if (y % 2 == 1) { //If Y is odd
                    offset = brickWidth / 2;
                }
                let xPix = p5.max(p5.min((x + brickWidth / 2), p5.width), 0);
                let yPix = p5.max(p5.min((y + brickHeight / 2), p5.height), 0);
                //console.log(`${xPix}, ${yPix}`);

                let thisColor = p5.get(xPix, p5.height - yPix);
                //console.log(thisColor);
                let sl = new ImageSlice(x - offset - canvasWidth / 2, y - canvasWidth / 2, brickWidth, brickHeight, p5.color(thisColor), p5);
                imageSlices.push(sl);
                p5.noStroke();
                p5.fill(thisColor);
                //rect(x-offset-200, y-200, brickWidth, brickHeight);
            }
        }
        loading = false;
        p5.rotate(0);

        //p5.saveCanvas(canvas, "screenshot", "png");
    }

    p5.setup = _ => {

        canvas = p5.createCanvas(canvasWidth, canvasWidth, p5.WEBGL);
        canvas.parent("p5Canvas");
        p5.pixelDensity(p5.displayDensity());
        p5.frameRate(30);
        p5.background(255);
        p5.ambientLight(51, 102, 126);
        p5.colorMode(p5.RGB, 255, 255, 255);

        //img = p5.loadImage('./img5.jpg');
        let imgs = ['img.jpg', 'img2.jpg', 'img3.jpg', 'img4.jpg', 'img5.jpg'];
        let rnmImg = imgs[p5.floor(p5.random(imgs.length))];
        img = p5.loadImage(require(`@/assets/images/${rnmImg}`), imageLoaded);
    }

    p5.windowResized = _ => {
        console.log("cool");
        canvasWidth = document.getElementById('p5Canvas').offsetWidth;
        p5.resizeCanvas(canvasWidth, canvasWidth);
    }

    p5.togglePlaying = _ => {
        playing = !playing;
    }

    p5.draw = _ => {

        if (loading) {
            p5.background(255);
            p5.stroke(loadingColor);
            p5.noFill();
            p5.translate(0, 0);
            p5.rotate(angle);
            p5.rect(-10, -10, 20, 20);
            angle += 0.1;
            return;
        }

        for (var i = 0; i < imageSlices.length; i++) {
            let s = imageSlices[i];
            s.render();
        }
    }

}