import * as Tone from "tone";
import { markRaw } from "vue";
import Generator from "@/utilities/markov";
//import { InstrumentPart } from "./instrument";
import InstrumentPart from "@/vaporwave/instrument";

class Chords extends InstrumentPart {
    constructor(bars, bank, buffers = null, parent = null) {

        super(bars, `bank_${bank}`, buffers);
        // converted midis
        this.bars = bars;
        this.midi = require('@/assets/json/Gradient-Tripping/Chords.json');
        // let source = buffers === null ? "lead_2.wav" : buffers.get("C3");


        this.sampler = new Tone.Sampler({
            urls: {
                C3: buffers.get("C3"),
                C4: buffers.get("C4"),
                C5: buffers.get("C5"),
            },
            baseUrl: `/audio/vaporwave/bank_${bank}/`,
            volume: this.volume,
            onload: () => {
                console.log("chords loaded");
            },
            onerror: (error) => {
                console.log("chords error");
            }
        })

        if (parent === null) {
            this.sampler.toDestination();
        } else {
            const panner = new Tone.Panner({
                pan: -0.3,
                channelCount: 2
            }).connect(parent);
            this.sampler.connect(panner);
        }

        let part = this.partFromMidi(this.midi);

        let includes = [0, 2, 5, 6, 8];
        let odds = [40];
        this.useOdds = false;
        for (let i = 0; i < 9; i++) {

            if (this.useOdds) {
                this.partFromOdds(odds, i, [part], null);
            } else {
                if (includes.includes(i)) {
                    let p = this.copyPart(part, bars * i);
                    this.parts.push(p);
                }
            }
        }

        this.populateSequence();
    }

}

export default Chords;