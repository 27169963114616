let p5;
let playing = false;
const Tri = require('./tri');
const VaporwaveColors = require('./vaporwaveColors');
let canvasWidth = document.getElementById('p5Canvas').offsetWidth;
var metadata;
let font;
let fontColor;

export function setData(data) {
  metadata = data.properties.properties.art;
}

export function main(_p5) {

  p5 = _p5;

  let bgc;

  var triangles = [];
  var count = metadata ? metadata.objectCount : 5;
  console.log(`count: ${count}`)
  var a = 0;
  var s = 0;
  let colors = new VaporwaveColors(p5);
  if (metadata) {
    colors.activeP = metadata.palette_id;
  }

  console.log("getting here ok");

  p5.preload = _ => {
    font = p5.loadFont('/plasmatic.otf');
  }

  // NOTE: Set up is here   
  p5.setup = _ => {
    console.log('any joe');
    var canvas = p5.createCanvas(canvasWidth, canvasWidth, p5.WEBGL);
    canvas.parent("p5Canvas");
    p5.pixelDensity(p5.displayDensity());
    p5.frameRate(30);
    p5.background(255);
    p5.ambientLight(51, 102, 126);
    p5.colorMode(p5.RGB, 255, 255, 255);
    for (var n = 0; n < count; n++) {
      if (metadata) {
        let data = metadata.objects[n];
        triangles.push(new Tri(p5, colors, data));
      } else {
        triangles.push(new Tri(p5, colors));
      }
    }
    console.log('saving canvas1');
    fontColor = colors.randomFrom();
    p5.saveCanvas(canvas, 'myCanvas', 'jpg');
    console.log('saving canvas');
  }

  p5.windowResized = _ => {
    console.log("cool");
    canvasWidth = document.getElementById('p5Canvas').offsetWidth;
    p5.resizeCanvas(canvasWidth, canvasWidth);
  }

  p5.togglePlaying = _ => {
    playing = !playing;
  }

  // NOTE: Draw is here
  p5.draw = _ => {
    p5.background(0);
    p5.lights();
    let c1 = p5.color(colors.active().bg1);
    let c2 = p5.color(colors.active().bg2);

    //color c2 = color(180, 233, 202);
    let interA = p5.lerpColor(c1, c2, s);
    p5.background(interA);
    for (var i = 0; i < count; i++) {
      let t = triangles[i];
      if (playing) {
        t.update();
      }

      t.draw();
    }

    a += 0.01;
    s = p5.sin(a);


  }
} 
