import { createApp } from 'vue'
import { createStore } from 'vuex'
import { VuexPersistence } from 'vuex-persist'

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
});

// Create a new store instance.
export const store = createStore({
    state() {
        return {
            walletId: "",
            count: 0
        }
    },
    getters: {
        walletId(state) {
            return state.walletId;
        }
    },
    mutations: {
        increment(state) {
            state.count++
        },
        updateWallet(state, walletId) {
            state.walletId = walletId
        },
    },
    plugins: [vuexLocal.plugin],
})