import Track from "@/classes/track";
import Drums from "@/vaporwave/albums/bezos/5/drums";
import Bass from "@/vaporwave/albums/bezos/5/bass";
import Lead from "@/vaporwave/albums/bezos/5/lead";
import FX from "@/vaporwave/albums/bezos/5/fx";
import Loops from "@/vaporwave/albums/bezos/5/loops";
import Harmony from "@/vaporwave/albums/bezos/5/harmony";
import Pluck from "@/vaporwave/albums/bezos/5/pluck";
import Chords from "@/vaporwave/albums/bezos/5/chords";
import * as Tone from "tone";

class KonnichiwaOpenSkies extends Track {

    constructor(kit, bank) {
        let k = Math.floor(Math.random() * 30) + 1;
        //super(kit, k, 'bezos5');
        super(5, 1, 'bezos5');
        this.bpm = 102;
    }

    load(parent = null) {

        if (parent) {
        }

        Tone.Transport.bpm.value = this.bpm;
        console.log(`transport bpm ${Tone.Transport.bpm.value}`);
        this.drums = new Drums(2, this.kit, null, this.drumBuffers, parent);
        this.bass = new Bass(8, this.bank, this.bassBuffers, parent);
        this.lead = new Lead(8, this.bank, this.leadBuffers, parent);
        this.harmony = new Harmony(8, this.bank, this.lead2Buffers, parent);
        this.pluck = new Pluck(8, this.bank, this.pluckBuffers, parent);
        this.chords = new Chords(8, this.bank, this.pluckBuffers, parent);
        this.instrumentParts = [this.drums, this.bass, this.lead, this.harmony, this.chords, this.pluck];

        for (const loop of this.loopBuffers) {
            let newLoop = new Loops(8, loop, parent);
            this.loops.push(newLoop);
            console.log(`OH YEAH ${newLoop}`);
        }
    }

}

export default KonnichiwaOpenSkies;