<template>
  <h1 class="title is-family-monospace mx-6 my-5">Hey!</h1>
  <div class="container columns mx-6 my-5">
    <div class="column is-full p-0">
      <div class="bottom-space">
        <div class="is-family-monospace">
          <div class="container columns">
            <div class="column is-one-half">
              <div class="content mb-5">
                <h4>Welcome to the Record Club from the future 🧑‍🚀</h4>
                We create and release one of a kind NFT albums. Each album is
                made in collaboration with a physical record store, and all
                profits from NFT sales are shared with that shop.
                <ul>
                  <li>
                    Albums are created based on specific genres of music and
                    released monthly. We're starting with
                    <a href="/vaporwave">Vaporwave</a>.
                  </li>
                  <li>
                    Proceeds from digital NFT sales are shared between the
                    creator and a physical record store in accordance with the
                    <a
                      href="https://docs.openzeppelin.com/contracts/2.x/api/payment"
                      >OpenZeppelin spec</a
                    >.
                  </li>
                  <li>
                    Each Record Club NFT release is digitally bespoke,
                    containing unique album art, animations, and music. Songs
                    can be downloaded as .mp3 files (or lossless .wavs), or
                    played directly in real-time via your collection on the
                    Record Club website.
                  </li>
                  <li>
                    Band, album, and track names are all generated by AI based
                    on music genre, at the time of NFT minting.
                  </li>
                  <li>
                    Our NFTs unlock membership to the record shop associated
                    with each release.
                  </li>
                  <li>
                    We are music and technology nerds who have been putting out
                    independent music for over a decade.
                  </li>
                </ul>

                <h4>Technical details</h4>
                <ul>
                  <li>
                    All music is generated at the time of minting and saved as
                    an .mp3 file, a high quality .wav file, and a series of
                    on-chain instructions that can be interpreted by the open
                    source javascript library
                    <a href="http://tonejs.github.io">tone.js.</a> You don't
                    just own a link to file, but also the bones of the album.
                  </li>
                  <li>
                    Record club is powered by the Polygon blockchain. Prices are
                    in MATIC.
                  </li>
                  <li>
                    All NFTs conform to the ERC-721 protocol and can be resold
                    on any NFT marketplace.
                  </li>
                  <li>
                    Digital assets (album art and album songs) are stored on
                    IFPS.
                  </li>
                  <li>
                    All songs can be recreated via our open source javascript
                    platform, meaning even if the original digital file is lost
                    it can be reconstructed using on-chain instructions and a
                    bit of javascript.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
export default {
  data() {
    return {
      images: [
        { src: "images/bezos/bezos_1.png" },
        { src: "images/bezos/bezos_2.png" },
        { src: "images/bezos/bezos_3.png" },
        { src: "images/bezos/bezos_4.png" },
        { src: "images/bezos/bezos_5.png" },
        { src: "images/bezos/bezos_6.png" },
        { src: "images/bezos/bezos_7.png" },
        { src: "images/bezos/bezos_8.png" },
        { src: "images/bezos/bezos_9.png" },
        { src: "images/bezos/bezos_10.png" },
        { src: "images/bezos/bezos_11.png" },
        { src: "images/bezos/bezos_12.png" },
      ],
    };
  },
  name: "About",
  setup() {},
  methods: {},
};
</script>
<style scoped>
</style>
