import Track from "@/classes/track";
import Drums from "@/vaporwave/albums/bezos/3/drums";
import Lead from "@/vaporwave/albums/bezos/3/lead";
import FX from "@/vaporwave/albums/bezos/3/fx";
import Harmony from "@/vaporwave/albums/bezos/3/harmony";
import Pluck from "@/vaporwave/albums/bezos/3/pluck";
import Loops from "@/vaporwave/albums/bezos/3/loops";
import Bass from "@/vaporwave/albums/bezos/3/bass"
import * as Tone from "tone";

class MallsoftDreams extends Track {

    constructor(kit, bank) {
        let k = Math.floor(Math.random() * 30) + 1;
        super(kit, k, 'bezos3');
        console.log(`bank ${k}`);
        //8
        this.bpm = 96;
    }

    load(parent = null) {

        Tone.Transport.bpm.value = this.bpm;
        console.log(`transport bpm ${Tone.Transport.bpm.value}`);
        this.drums = new Drums(8, this.kit, null, this.drumBuffers, parent);
        this.bass = new Bass(8, this.bank, this.bassBuffers, parent);
        this.lead = new Lead(8, this.bank, this.leadBuffers, parent);
        this.fx = new FX(4, this.bank, this.fxBuffers, parent);
        this.harmony = new Harmony(8, this.bank, this.lead2Buffers, parent);
        this.pluck = new Pluck(8, this.bank, this.pluckBuffers, parent);
        this.instrumentParts = [this.lead, this.drums, this.harmony, this.pluck, this.bass];
        this.instrumentParts = [this.lead, this.drums, this.harmony, this.bass];

        for (const loop of this.loopBuffers) {
            let newLoop = new Loops(8, loop, parent);
            this.loops.push(newLoop);
        }

    }

}

export default MallsoftDreams;