<template>
  <div class="container columns m-1">
    <div class="column is-full">
      <div class="d-flex justify-content-center" id="p5Canvas"></div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  // Using the composition API to set our component variables
  setup() {
    // set BPM
    var sketch; // = require("../assets/p5/1/sketch");
    let id;
    let isPlaying = ref(false);
    let buttonText = ref("Play");

    return {
      id,
      sketch,
      isPlaying,
      buttonText,
    };
  },
  async mounted() {
    //this.id = parseInt(this.$route.params.id);
    this.id = this.$route.params.id;
    let json = JSON.parse(this.$route.query.data);
    let autoplay = false;
    if (this.$route.query.autoplay) {
      if (this.$route.query.autoplay === "true") {
        autoplay = true;
      }
    }
    console.log(`json!!! ${json}`);
    console.log(`id!!! ${this.id}`);

    this.sketch = require(`../assets/p5/${this.id}/sketch`);
    const P5 = require("p5");
    this.sketch.setData(json, autoplay);
    this.p5 = new P5(this.sketch.main);
  },
  props: ["id"],
};
</script>

<style scoped>
</style>
