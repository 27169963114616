let p5;
const RCBox = require('./rcbox');
const VaporwaveColors = require('./vaporwaveColors');
let playing = false;
let canvasWidth = document.getElementById('p5Canvas').offsetWidth;
let metadata;

export function setData(data) {
    metadata = data.properties.properties.art;
}

export function main(_p5) {

    p5 = _p5;

    let boxes = [];
    let w;
    let h;
    let grid = 50;
    let maxSize = 200;
    let drawing = true;
    let colors = new VaporwaveColors(p5);

    function randomInGrid() {
        let r = p5.random(p5.width - (grid));
        return p5.round(r / grid) * grid - 200;
    }

    p5.setup = _ => {
        var canvas = p5.createCanvas(canvasWidth, canvasWidth, p5.WEBGL);
        canvas.parent("p5Canvas");
        console.log(`canvas width ${canvasWidth}`);
        p5.pixelDensity(p5.displayDensity());
        p5.frameRate(30);
        p5.background(255);
        p5.ambientLight(51, 102, 126);
        p5.colorMode(p5.RGB, 255, 255, 255);
        w = p5.width;
        h = p5.height;
        colors.activeP = p5.floor(p5.random(colors.palettes.length));

        for (var i = 0; i < 1000; i++) {
            let side = p5.random(maxSize);
            let snappedSide = p5.round(side / grid) * grid;
            let b = new RCBox(randomInGrid(), randomInGrid(), snappedSide, snappedSide, p5, colors, maxSize, grid);
            boxes.push(b);
        }

    }

    p5.windowResized = _ => {
        console.log("cool");
        canvasWidth = document.getElementById('p5Canvas').offsetWidth;
        p5.resizeCanvas(canvasWidth, canvasWidth);
    }

    p5.togglePlaying = _ => {
        playing = !playing;
    }

    p5.mouseClicked = _ => {
        refresh();
    }

    p5.draw = _ => {

        p5.background(colors.active().bg1);
        p5.noStroke();

        //lights();
        for (var i = 0; i < boxes.length; i++) {
            // Draw a rectangle
            let r = boxes[i];
            if (playing) {
                r.update();
            }

            r.draw();
        }
    }

    function refresh() {
        //colors.randomFrom();
        //boxes.clear();
        //colors.activeP = floor(random(11));
        drawing = true;
        for (var i = 0; i < boxes.length; i++) {
            // Draw a rectangle
            let r = boxes[i];
            r.moveTo(p5.createVector(randomInGrid(), randomInGrid()));
        }
    }

}