<template>
  <div v-bind:style="styleObject" id="p5Canvas"></div>
  <div>
    <h1 class="title is-family-monospace mx-6 my-5">Welcome to Record Club</h1>
    <div class="container columns mx-6 my-5">
      <div class="column is-full">
        <div class="bottom-space">
          <div class="is-family-monospace">
            <!-- this is thing to make transparent -->
            <div
              class="box container columns"
              :style="{ backgroundColor: 'transparent' }"
            >
              <div class="column is-one-quarter">
                <a href="albums/1"
                  ><img width="200" src="@/assets/images/bezos.png"
                /></a>
              </div>
              <div class="column is-three-quarters">
                <div class="has-text-dark has-text-weight-bold is-size-4">
                  Bezos Fantagram
                </div>
                <div class="my-3" has-text-weight-bold>
                  <a href="albums/1">リサフランク420</a>
                </div>
                <div class="my-3 is-size-8 has-text-dark has-text-weight-bold">
                  リサフランク420 is the new album from Bezos Fantagram. It's a
                  masterpiece. Both retro and contemporary, it's a must-hear for
                  fans of classic rock and electronica alike. Textures collide
                  into each other like asteroids in space! The album begins with
                  a truly epic song, one that sets the tone for the rest of the
                  album. If you're looking for something to listen to, be sure
                  to make that thing Bezos Fantagram's latest release,
                  リサフランク420.
                </div>

                <div class="is-size-7 has-text-weight-bold has-text-dark">
                  120 / 1000 sold
                </div>
                <div class="is-size-7 has-text-weight-bold has-text-dark">
                  40 MATIC
                </div>
                <div class="my-4">
                  <o-button id="hide-seen" @click="connectWallet">Buy</o-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
export default {
  name: "Main",
  data() {
    return {
      styleObject: {
        width: "100%",
        height: "100%",
        opacity: 1,
        position: "fixed",
        zIndex: "0 !important", // this doesn't work
      },
    };
  },
  setup() {
    var sketch;
    return {
      sketch,
    };
  },
  async mounted() {
    this.sketch = require(`../assets/p5/main/sketch`);
    const P5 = require("p5");
    this.p5 = new P5(this.sketch.main);
  },
  methods: {},
};
</script>
<style scoped>
</style>