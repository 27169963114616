import { createApp } from 'vue'
import { Vue } from 'vue'
import App from './App.vue'
import Oruga from '@oruga-ui/oruga-next'
import { bulmaConfig } from '@oruga-ui/theme-bulma'
import { store } from './store/'
import '@oruga-ui/theme-bulma/dist/bulma.css'
import router from './router'
import './assets/style.css';

const app = createApp(App)
    .use(router)
    .use(store)
    .use(Oruga, bulmaConfig)
    .mount('#app')