import * as Tone from "tone";
import { markRaw } from "vue";
import Generator from "@/utilities/markov";
//import { InstrumentPart } from "./instrument";
import InstrumentPart from "@/vaporwave/instrument";

class Harmony extends InstrumentPart {
    constructor(bars, bank, buffers = null, parent = null) {

        super(bars, `bank_${bank}`, buffers);
        // converted midis
        this.midiA = require('@/assets/json/ROM Dealer/Harmony_1.json'); //with path
        this.midiB = require('@/assets/json/ROM Dealer/Harmony_2.json'); //with path

        // let source = buffers === null ? "lead_2.wav" : buffers.get("C3");

        this.sampler = new Tone.Sampler({
            urls: {
                C3: buffers.get("C3"),
                C4: buffers.get("C4"),
                C5: buffers.get("C5"),
            },
            baseUrl: `/audio/vaporwave/bank_${bank}/`,
            volume: this.volume - 1,
            onload: () => {
                console.log("harmony loaded");
            },
            onerror: (error) => {
                console.log("harmony error");
            }
        })

        if (parent === null) {
            this.sampler.toDestination();
        } else {
            const panner = new Tone.Panner({
                pan: -0.3,
                channelCount: 2
            }).connect(parent);
            this.sampler.connect(panner);
        }

        let partA = this.partFromMidi(this.midiA);
        let partB = this.partFromMidi(this.midiB);

        let aIncludes = [3, 4];
        let bIncludes = [7];
        for (let i = 0; i < 11; i++) {
            if (aIncludes.includes(i)) {
                let p = this.copyPart(partA, bars * i);
                this.parts.push(p);
            } else if (bIncludes.includes(i)) {
                let p = this.copyPart(partB, bars * i);
                this.parts.push(p);
            }
        }

        this.populateSequence();
    }

}

export default Harmony;