import * as Tone from "tone";
import InstrumentPart from "@/vaporwave/instrument";

class Bass extends InstrumentPart {
    constructor(bars, bank, buffers = null, parent = null) {

        super(bars, `bank_${bank}`, buffers);

        console.log(`transport bpm ${Tone.Transport.bpm.value
            }`);

        this.midiA = require('@/assets/json/ASoCiAl MeDiA NeOn PoP/Bass A.json');
        this.midiB = require('@/assets/json/ASoCiAl MeDiA NeOn PoP/Bass B.json');

        let source = buffers === null ? "bass.wav" : buffers.get("C1");

        this.sampler = new Tone.Sampler({
            urls: {
                C1: buffers.get("C1"),
                C2: buffers.get("C2"),
                C3: buffers.get("C3"),
            },
            baseUrl: `/audio/vaporwave/bank_${bank}/`,
            volume: this.volume,
            onload: () => {
                console.log("bass loaded");
            },
        });

        if (parent === null) {
            this.sampler.toDestination();
        } else {
            const panner = new Tone.Panner({
                pan: -0.3,
                channelCount: 2
            }).connect(parent);
            this.sampler.connect(panner);
        }

        this.bars = bars;

        let partA = this.partFromMidi(this.midiA);
        let partB = this.partFromMidi(this.midiB);

        let aIncludes = [0, 1, 2, 3, 5, 7, 8, 9, 10, 11];
        let bIncludes = [];//[3, 4, 9, 10];
        for (let i = 0; i < 12; i++) {
            if (aIncludes.includes(i)) {
                let p = this.copyPart(partA, bars * i);
                this.parts.push(p);
            } else if (bIncludes.includes(i)) {
                let p = this.copyPart(partB, bars * i);
                this.parts.push(p);
            }
        }

        // make the track
        this.populateSequence();
    }

    makePart() {
        let part = [];
        let beat = 0;
        let bar = 0;
        let sixteenth = 0;

        this.steps = ["C1", "C2", "C1", "C2"];
        this.currentNotePosition = 0;

        for (var x = 0; x < this.bars; x += 1) {
            bar = x;
            for (var y = 0; y < 4; y += 1) {
                beat = y;
                sixteenth = 0;

                let currentNote = this.steps[this.currentNotePosition];

                if (beat % 2 === 0) {
                    part.push({
                        time: `${bar}:${beat}:${sixteenth}`,
                        note: currentNote,
                        duration: "2n",
                        velocity: 1,
                    });
                    if (this.currentNotePosition < this.steps.length - 1) {
                        this.currentNotePosition++;
                    } else {
                        this.currentNotePosition = 0;
                    }

                }
            }

            beat = 0;
            sixteenth = 0;
        }

        return part;
    }
}

export default Bass;    