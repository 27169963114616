import * as Tone from "tone";
import InstrumentPart from "@/vaporwave/instrument";

class Drums extends InstrumentPart {
    constructor(bars, kit, drumData = null, buffers = null, parent = null) {

        super(bars, `kit_${kit}`, buffers);
        let urls;
        this.drumData = drumData
        this.bars = bars;

        this.midiA = require('@/assets/json/Gradient-Tripping/Drums_1.json');
        this.midiB = require('@/assets/json/Gradient-Tripping/Drums_2.json');

        if (buffers === null) {
            urls = {
                C1: "kick.wav",
                D1: "snare.wav",
                E1: "closed_hat.wav",
                F1: "open_hat.wav",
                G2: "tom_1.wav",
                A2: "tom_2.wav",
                B2: "tom_3.wav",
                G3: "perc_1.wav",
                A3: "perc_2.wav",
                B3: "crash.wav",
                C3: "ride.wav",
            }
        } else {
            urls = {
                C1: buffers.get("C1"),
                D1: buffers.get("D1"),
                E1: buffers.get("E1"),
                F1: buffers.get("F1"),
                G2: buffers.get("G2"),
                A2: buffers.get("A2"),
                B2: buffers.get("B2"),
                G3: buffers.get("G3"),
                A3: buffers.get("A3"),
                B3: buffers.get("B3"),
                C3: buffers.get("C3"),
            }
        }

        this.sampler = new Tone.Sampler({
            urls: urls,
            baseUrl: `/audio/vaporwave/kit_${kit}/`,
            volume: this.volume - 4,
            onload: () => {
                console.log("drums loaded");
            },
            onerror: (error) => {
                console.log("drums error");
            }
        });

        if (parent === null) {
            this.sampler.toDestination();
        } else {
            const autoPanner = new Tone.AutoPanner("4n").toDestination().connect(parent);
            this.sampler.connect(autoPanner);
        }

        const reverb = new Tone.Reverb({
            decay: 10,
            preDelay: 0.5,
            wet: 0.5,
        }).toDestination();
        this.sampler.connect(reverb);

        let partA = this.partFromMidi(this.midiA);
        let partB = this.partFromMidi(this.midiB);

        let aIncludes = [0, 1, 4, 5, 10, 11, 12, 13, 16, 17];
        let bIncludes = [2, 3, 6, 7];
        for (let i = 0; i < 18; i++) {

            if (aIncludes.includes(i)) {
                let p = this.copyPart(partA, bars * i);
                this.parts.push(p);
            } else if (bIncludes.includes(i)) {
                let p = this.copyPart(partB, bars * i);
                this.parts.push(p);
            }

            let fillPart = this.makePart(bars * i, bars);
            this.parts.push(fillPart);
        }

        this.populateSequence();
    }

    makePart(start, length, type = "hats") {

        let part = [];

        // bars
        let bar = 0;
        let beat = 0;
        let sixteenth = 0;

        // things to control w/ contract
        let crashOdds = this.drumData === null ? 20 : this.drumData.crashOdds;
        let fillOdds = this.drumData === null ? 10 : this.drumData.fillOdds;

        for (var x = start; x < start + length; x += 1) {
            //beats (division)
            bar = x;

            for (var y = 0; y < 4; y += 1) {
                beat = y;
                sixteenth = 0;

                // irregularly make fills on beat 2 or 3
                let fillBeats = [2];
                let startFillBeat = fillBeats[Math.floor(Math.random() * fillBeats.length)];
                if (beat === startFillBeat) {
                    let shouldFill = Math.floor(Math.random() * fillOdds) === 0;
                    if (shouldFill) {
                        console.log('should fill');
                        let fillLength = startFillBeat === 3 ? 2 : 1;
                        let fill = this.makeFill(bar, beat, fillLength);
                        for (var f = 0; f < fill.length; f++) {
                            part.push(fill[f]);
                        }
                        continue;
                    }
                }

                //sixteenths
                for (var z = 0; z < 4; z += 1) {
                    sixteenth = z;

                    // irregularly make other perc

                }
            }

            beat = 0;
            sixteenth = 0;
        }

        return part;
    }

    makeDropPart(start, length) {

        let part = [];
        let bar = 0;
        let beat = 0;

        for (var x = start; x < start + length; x += 1) {

            //beats (division)
            bar = x;
            for (var y = 0; y < 4; y += 1) {
                beat = y;

                let fillBeats = [2];
                if (beat === 2 && bar === start + 3) {
                    let fillLength = 2;
                    let fill = this.makeFill(bar, beat, fillLength, 10);
                    for (var f = 0; f < fill.length; f++) {
                        part.push(fill[f]);
                    }
                }
            }
            beat = 0;
        }

        return part;
    }

    makeFill(bar, beatStart, beatLength, odds = 8) {

        let options = ['G2', 'A2', 'B2'];
        let note = Math.floor(Math.random() * options.length);
        let fill = [];
        let vel = 0.6;
        for (var x = beatStart; x < beatStart + beatLength; x += 1) {
            for (var y = 0; y < 4; y += 1) {

                // there's a 8/10 chance we add a shot to our 16th note
                let rInt = Math.floor(Math.random() * 10);
                if (rInt > odds) {
                    continue;
                }
                fill.push({
                    time: `${bar}:${x}:${y}`,
                    note: options[Math.floor(Math.random() * options.length)],
                    duration: "8n",
                    velocity: 1,
                });
                vel += 0.1;
            }
        }

        return fill;
    }
}

export default Drums;