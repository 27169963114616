//let p5;

module.exports = class Palette {

  constructor(colors) {
    this.colors = colors;
    this.bg1 = this.colors[0];
    this.bg2 = this.colors[1];
  }

}