import Track from "@/classes/track";
import Drums from "@/vaporwave/albums/bezos/2/drums";
import Bass from "@/vaporwave/albums/bezos/2/bass";
import Lead from "@/vaporwave/albums/bezos/2/lead";
import FX from "@/vaporwave/albums/bezos/2/fx";
import Harmony from "@/vaporwave/albums/bezos/2/harmony";
import Pluck from "@/vaporwave/albums/bezos/2/pluck";
import Chords from "@/vaporwave/albums/bezos/2/chords";
import Loops from "@/vaporwave/albums/bezos/2/loops";
import * as Tone from "tone";

class ASoCiAlMeDiANeOnPoP extends Track {

    constructor(kit, bank) {
        let k = Math.floor(Math.random() * 30) + 1;
        super(kit, k, 'bezos2');
        this.bpm = 103;
    }

    load(parent = null) {
        Tone.Transport.bpm.value = this.bpm;
        console.log(`transport bpm ${Tone.Transport.bpm.value}`);
        this.drums = new Drums(8, this.kit, null, this.drumBuffers, parent);
        this.bass = new Bass(8, this.bank, this.bassBuffers, parent);
        this.lead = new Lead(8, this.bank, this.leadBuffers, parent);
        this.fx = new FX(8, this.bank, this.fxBuffers, parent);
        this.harmony = new Harmony(8, this.bank, this.lead2Buffers, parent);
        this.pluck = new Pluck(8, this.bank, this.pluckBuffers, parent);
        this.chords = new Chords(8, this.bank, this.pluckBuffers, parent);
        this.instrumentParts = [this.lead, this.pluck, this.drums, this.bass, this.harmony, this.chords];

        this.loops = [];

        for (const loop of this.loopBuffers) {
            let newLoop = new Loops(8, loop, parent);
            this.loops.push(newLoop);
        }
    }

}

export default ASoCiAlMeDiANeOnPoP;