import * as Tone from "tone";
import { markRaw } from "vue";
import Generator from "@/utilities/markov";
//import { InstrumentPart } from "./instrument";
import InstrumentPart from "@/vaporwave/instrument";

class Lead extends InstrumentPart {
    constructor(bars, bank, buffers = null, parent = null) {

        super(bars, `bank_${bank}`, buffers);

        this.midi = require('@/assets/json/ROM Dealer/Lead.json');

        let source = buffers === null ? "lead.wav" : buffers.get("C3");
        console.log(`buffers ${buffers}`)
        console.log(`source ${source}`);

        this.sampler = new Tone.Sampler({
            urls: {
                C3: buffers.get("C3"),
                C4: buffers.get("C4"),
                C5: buffers.get("C5"),
            },
            baseUrl: `/audio/vaporwave/bank_${bank}/`,
            volume: this.volume,
            onload: () => {
                console.log("lead loaded");
            },
            onerror: (error) => {
                console.log("lead error");
            }
        })

        if (parent === null) {
            this.sampler.toDestination();
        } else {
            const panner = new Tone.Panner({
                pan: -0.8,
                channelCount: 2
            }).connect(parent);
            const chorus = new Tone.Chorus(4, 2.5, 0.5).connect(panner).start();
            this.sampler.connect(chorus);
            //this.sampler.connect(panner);
        }

        const pingPong = new Tone.PingPongDelay("8n", 0.2).toDestination();
        this.sampler.connect(pingPong);

        let part = this.partFromMidi(this.midi);
        let markovPart = this.markovPart(part, 0);

        let markovIncludes = [6, 7, 8];
        for (let i = 0; i < 11; i++) {

            let p = this.copyPart(part, bars * i);
            this.parts.push(p);
            if (markovIncludes.includes(i)) {
                let p = this.copyPart(markovPart, bars * i);
                this.parts.push(p);
            }
        }

        this.populateSequence();
    }

}

export default Lead;