let p5;
let playing = false;
let canvasWidth = document.getElementById('p5Canvas').offsetWidth;
let canvasHeight = document.getElementById('p5Canvas').offsetHeight;
var metadata;
let font;
let fontColor;
const VaporwaveColors = require('./vaporwaveColors');
var s = 0;
var a = 0;
let c1;
let c2;

export function setData(data) {
  metadata = data.properties.properties.art;
}

export function main(_p5) {

  p5 = _p5;

  var cols, rows;
  var scl = 25; //Math.random() * 70 + 25;
  var w = canvasWidth * 1.3;
  var h = canvasHeight;
  var flying = 0;
  var terrain = [];

  p5.preload = _ => {
    //font = p5.loadFont('/plasmatic.otf');
  }

  // NOTE: Set up is here   
  p5.setup = _ => {
    var canvas = p5.createCanvas(canvasWidth, canvasHeight, p5.WEBGL);
    canvas.parent("p5Canvas");
    p5.pixelDensity(p5.displayDensity());
    p5.frameRate(60);
    cols = w / scl;
    rows = h / scl;

    let colors = new VaporwaveColors(p5);
    c1 = p5.color(colors.active().bg1);
    c2 = p5.color(colors.active().bg2);

    for (var x = 0; x < cols; x++) {
      terrain[x] = [];
      for (var y = 0; y < rows; y++) {
        terrain[x][y] = 0; //specify a default value for now
      }
    }
  }

  p5.windowResized = _ => {
    console.log("cool");
    canvasWidth = document.getElementById('p5Canvas').offsetWidth;
    p5.resizeCanvas(canvasWidth, canvasWidth);
  }

  // NOTE: Draw is here
  p5.draw = _ => {
    flying -= 0.1;
    var yoff = flying;
    for (var y = 0; y < rows; y++) {
      var xoff = 0;
      for (var x = 0; x < cols; x++) {
        //terrain[x][y] = p5.map(p5.noise(xoff, yoff), 0, 1, -300, 300);
        terrain[x][y] = p5.map(p5.noise(xoff, yoff), 0, 1, -300, 300);
        xoff += 0.1;
      }
      yoff += 0.25;
    }

    console.log("drawing");

    p5.background(255);
    p5.translate(0, 50);
    p5.rotateX(p5.PI / 3);
    //p5.fill(255, 255, 0, 50);
    p5.noFill();

    let interA = p5.lerpColor(c1, c2, s);
    p5.stroke(interA);
    p5.translate(-w / 2, -h / 2);
    for (var y = 0; y < rows - 1; y++) {
      p5.beginShape(p5.TRIANGLE_STRIP);
      for (var x = 0; x < cols; x++) {
        p5.vertex(x * scl, y * scl, terrain[x][y]);
        p5.vertex(x * scl, (y + 1) * scl, terrain[x][y + 1]);
      }
      p5.endShape();
    }

    a += 0.01;
    s = p5.sin(a);
  }


} 
