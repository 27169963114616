import * as Tone from "tone";
import InstrumentPart from "@/vaporwave/instrument";

class Bass extends InstrumentPart {
    constructor(bars, bank, buffers = null, parent = null) {

        super(bars, `bank_${bank}`, buffers);

        this.midi = require('@/assets/json/Gradient-Tripping/Bass.json');

        this.sampler = new Tone.Sampler({
            urls: {
                C1: buffers.get("C1"),
                C2: buffers.get("C2"),
                C3: buffers.get("C3"),
            },
            baseUrl: `/audio/vaporwave/bank_${bank}/`,
            volume: this.volume,
            onload: () => {
                console.log("bass loaded");
            },
            onerror: (error) => {
                console.log("bass error");
            }
        });

        if (parent === null) {
            this.sampler.toDestination();
        } else {
            const panner = new Tone.Panner({
                pan: 0.3,
                channelCount: 2
            }).connect(parent);
            this.sampler.connect(panner);
        }

        this.bars = bars;

        let part = this.partFromMidi(this.midi);

        let includes = [0, 1, 2, 3, 4, 6, 7, 8];
        let odds = [70];

        this.useOdds = false;

        for (let i = 0; i < 9; i++) {

            if (this.useOdds) {
                this.partFromOdds(odds, i, [part], null);
            } else {
                if (includes.includes(i)) {
                    let p = this.copyPart(part, bars * i);
                    this.parts.push(p);
                }
            }

        }

        // make the track
        this.populateSequence();
    }

    makePart() {
        let part = [];
        let beat = 0;
        let bar = 0;
        let sixteenth = 0;

        this.steps = ["C1", "C2", "C1", "C2"];
        this.currentNotePosition = 0;

        for (var x = 0; x < this.bars; x += 1) {
            bar = x;
            for (var y = 0; y < 4; y += 1) {
                beat = y;
                sixteenth = 0;

                let currentNote = this.steps[this.currentNotePosition];

                if (beat % 2 === 0) {
                    part.push({
                        time: `${bar}:${beat}:${sixteenth}`,
                        note: currentNote,
                        duration: "2n",
                        velocity: 1,
                    });
                    if (this.currentNotePosition < this.steps.length - 1) {
                        this.currentNotePosition++;
                    } else {
                        this.currentNotePosition = 0;
                    }

                }
            }

            beat = 0;
            sixteenth = 0;
        }

        return part;
    }
}

export default Bass;    