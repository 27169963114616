import * as Tone from "tone";

class Track {

    constructor(kit, bank, name = 'bezos') {

        this.samplesLoaded = false;
        this.loadingSeconds = 0;
        this.instrumentParts = [];
        this.kit = kit;
        this.bank = bank;
        this.bpm = 100;
        this.refreshIntervalId;
        console.log(`INIT TRACK WITH NAME ${name} `);

        this.leadBuffers;
        this.lead2Buffers;
        this.pluckBuffers;
        this.drumBuffers;
        this.bassBuffers;
        this.fxBuffers;

        this.loadBuffers(bank, kit);
        this.useOdds = false;

        this.loopBuffers = [];

        if (name === 'bezos4') {
            this.loopBuffers.push(new Tone.ToneAudioBuffer(`/audio/vaporwave/loops/bezos4/1.wav`));
        } else if (name === 'bezos3') {
            this.loopBuffers.push(new Tone.ToneAudioBuffer(`/audio/vaporwave/loops/bezos3/1.wav`));
        } else if (name === 'bezos5') {
            let randLoop = Math.floor(Math.random() * 2) + 1;
            console.log(`RANDOM LOOP ${randLoop}`);
            this.loopBuffers.push(new Tone.ToneAudioBuffer(`/audio/vaporwave/loops/bezos5/${randLoop}.wav`));
        } else if (name === 'bezos2') {
            this.loopBuffers.push(new Tone.ToneAudioBuffer(`/audio/vaporwave/loops/bezos2/1.wav`));
        } else if (name === 'bezos1') {
            this.loopBuffers.push(new Tone.ToneAudioBuffer(`/audio/vaporwave/loops/bezos1/1.wav`));
        }

        this.drums;
        this.bass;
        this.lead;
        this.fx;
        this.harmony;
        this.pluck;
        this.chords;
        this.loops = [];
    }

    loadBuffers(bank, kit) {
        this.leadBuffers = new Tone.ToneAudioBuffers({
            urls: {
                C3: `/audio/vaporwave/leads/${bank}/slow/C3.wav`,
                C4: `/audio/vaporwave/leads/${bank}/slow/C4.wav`,
                C5: `/audio/vaporwave/leads/${bank}/slow/C5.wav`,
            },
            onload: () => console.log("track lead buffers loaded"),
            onerror: (error) => {
                console.log(`track lead buffer error ${error} bank ${bank} kit ${kit}`);
            }
        });

        this.lead2Buffers = new Tone.ToneAudioBuffers({
            urls: {
                C3: `/audio/vaporwave/leads/${bank}/slow/C3.wav`,
                C4: `/audio/vaporwave/leads/${bank}/slow/C4.wav`,
                C5: `/audio/vaporwave/leads/${bank}/slow/C5.wav`,
            },
            onload: () => console.log("track lead2 buffers loaded"),
            onerror: (error) => {
                console.log(`track lead2 buffer error ${error} bank ${bank} kit ${kit}`);
            }
        });

        console.log(`this.pluckBuffers ${`/audio/vaporwave/plucks/${bank}/converted/C3.wav`}`)
        this.pluckBuffers = new Tone.ToneAudioBuffers({
            urls: {
                C3: `/audio/vaporwave/plucks/${bank}/converted/C3.wav`,
                C4: `/audio/vaporwave/plucks/${bank}/converted/C4.wav`,
                C5: `/audio/vaporwave/plucks/${bank}/converted/C5.wav`,
            },
            onload: () => console.log("track pluck buffers loaded"),
            onerror: (error) => {
                console.log(`track pluck buffer error ${error} bank ${bank} kit ${kit}`);
            }
        });

        this.bassBuffers = new Tone.ToneAudioBuffers({
            urls: {
                C1: `/audio/vaporwave/bass/${bank}/C1.wav`,
                C2: `/audio/vaporwave/bass/${bank}/C2.wav`,
                C3: `/audio/vaporwave/bass/${bank}/C3.wav`,
            },
            onload: () => console.log("track bass buffers loaded"),
            onerror: (error) => {
                console.log(`track bass buffer error ${error} bank ${bank} kit ${kit}`);
            }
        });

        // everything is normalized at -4
        // hats at -10

        this.drumBuffers = new Tone.ToneAudioBuffers({
            urls: {
                'C1': `/audio/vaporwave/kit_${kit}_super_slow/normalized/kick.wav`,
                'D1': `/audio/vaporwave/kit_${kit}_super_slow/normalized/snare.wav`,
                'D#1': `/audio/vaporwave/kit_${kit}/normalized/clap.wav`,
                'E1': `/audio/vaporwave/kit_${kit}/normalized/closed_hat.wav`,
                'F1': `/audio/vaporwave/kit_${kit}/normalized/open_hat.wav`,
                'G2': `/audio/vaporwave/kit_${kit}_super_slow/normalized/tom_1.wav`,
                'A2': `/audio/vaporwave/kit_${kit}_super_slow/normalized/tom_2.wav`,
                'B2': `/audio/vaporwave/kit_${kit}_super_slow/normalized/tom_3.wav`,
                'G3': `/audio/vaporwave/kit_${kit}_super_slow/normalized/perc_1.wav`,
                'A3': `/audio/vaporwave/kit_${kit}_super_slow/normalized/perc_2.wav`,
                'B3': `/audio/vaporwave/kit_${kit}_super_slow/normalized/crash.wav`,
                'C3': `/audio/vaporwave/kit_${kit}/normalized/ride.wav`,
            },
            onload: () => console.log("drums buffers loaded"),
            onerror: (error) => {
                console.log("drums buffer error");
            },
            onload: () => console.log("track drums buffers loaded"),
            onerror: (error) => {
                console.log(`track drums buffer error ${error} bank ${bank} kit ${kit}`);
            }
        });

        this.fxBuffers = new Tone.ToneAudioBuffers({
            urls: {
                C1: `/audio/vaporwave/fx/vocals/1.wav`,
                D1: `/audio/vaporwave/fx/vocals/2.wav`,
                E1: `/audio/vaporwave/fx/vocals/3.wav`,
                F1: `/audio/vaporwave/fx/vocals/4.wav`,
                G1: `/audio/vaporwave/fx/vocals/5.wav`,
                A1: `/audio/vaporwave/fx/vocals/6.wav`,
                D2: `/audio/vaporwave/fx/vocals/Bezos4_1.wav`,
                E2: `/audio/vaporwave/fx/vocals/Bezos4_2.wav`
            },
            onload: () => console.log("track fx buffers loaded"),
            onerror: (error) => {
                console.log(`track fx buffer error ${error} bank ${bank} kit ${kit}`);
            }
        });

        this.refreshIntervalId = setInterval(() => this.checkSamples(), 1000);
    }

    checkSamples() {

        console.log(`this.drumBuffers.loaded ${this.drumBuffers.loaded}`)
        console.log(`this.pluckBuffer.loaded ${this.pluckBuffers.loaded}`)
        console.log(`this.leadBuffers.loaded ${this.leadBuffers.loaded}`)
        console.log(`this.lead2Buffers.loaded ${this.lead2Buffers.loaded}`)
        console.log(`this.bassBuffers.loaded ${this.bassBuffers.loaded}`)
        console.log(`this.fxBuffers.loaded ${this.fxBuffers.loaded}`)

        let allLoaded = true;
        for (const instrument of this.instrumentParts) {
            console.log(`loaded ${instrument.sampler.loaded}`)
            if (instrument.sampler.loaded === false) {
                allLoaded = false;
                break;
            }
        }

        console.log(`checking samples .loaded ${allLoaded}`);

        this.samplesLoaded = allLoaded;

        if (this.samplesLoaded === true && this.refreshIntervalId) {
            clearInterval(this.refreshIntervalId);
        }

        this.loadingSeconds++;

        console.log(`loading for ${this.loadingSeconds}`);
        if (this.loadingSeconds > 5) {
            if (this.samplesLoaded === false && this.refreshIntervalId) {
                clearInterval(this.refreshIntervalId);
            }
            // reload
            console.log(`.loading reloading buffers`);
            this.loadBuffers(this.bank, this.kit);
            this.loadingSeconds = 0;

        }

        return this.samplesLoaded;
    }

    play(pos, time) {
        for (var i = 0; i < this.instrumentParts.length; i++) {
            let instrument = this.instrumentParts[i];
            instrument.triggerSample(pos, time);
        }
        for (var l = 0; l < this.loops.length; l++) {
            let loop = this.loops[l];
            loop.triggerSample(pos, time);
        }
    }

}

export default Track;