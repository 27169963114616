import * as Tone from "tone";
import { markRaw } from "vue";
import Generator from "@/utilities/markov";
//import { InstrumentPart } from "./instrument";
import InstrumentPart from "@/vaporwave/instrument";

class Harmony extends InstrumentPart {
    constructor(bars, bank, buffers = null, parent = null) {

        super(bars, `bank_${bank}`, buffers);
        // converted midis
        this.midi = require('@/assets/json/Mallsoft Dreams/Chords.json'); //with path

        this.sampler = new Tone.Sampler({
            urls: {
                C3: buffers.get("C3"),
                C4: buffers.get("C4"),
                C5: buffers.get("C5"),
            },
            baseUrl: `/audio/vaporwave/bank_${bank}/`,
            volume: this.volume,
            onload: () => {
                console.log("harmony loaded");
            },
        })

        if (parent === null) {
            this.sampler.toDestination();
        } else {
            const panner = new Tone.Panner({
                pan: 0.3,
                channelCount: 2
            }).connect(parent);
            this.sampler.connect(panner);
        }

        let part = this.partFromMidi(this.midi);

        for (let i = 0; i < 8; i++) {
            let p = this.copyPart(part, bars * i);
            this.parts.push(p);
        }

        this.populateSequence();
    }

}

export default Harmony;