<template>
  <h1 class="title is-family-monospace mx-6 my-5">Your collection buddy!</h1>
  <div class="container columns m-5">
    <div style="flex-wrap: wrap" class="tile is-ancestor">
      <div
        class="column is-one-quarter"
        v-for="album in _collection"
        :key="album"
      >
        <div class="bottom-space">
          <div class="is-family-monospace">
            <div class="box">
              <a
                :href="`/collection/1/album/bezos?token=${parseInt(
                  album.id.tokenId,
                  16
                )}`"
              >
                <figure class="image is-1by1">
                  <img :src="album.metadata.image" alt="images/default.png" />
                </figure>
              </a>
            </div>
            <div>{{ album.metadata.attributes.artist }}</div>
            <div>
              <a
                :href="`/collection/1/album/bezos?token=${parseInt(
                  album.id.tokenId,
                  16
                )}`"
                >{{ album.metadata.attributes.album }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { createAlchemyWeb3 } from "@alch/alchemy-web3";
import { store } from "../store";

export default {
  name: "Home",
  data() {
    return {
      _collection: null,
    };
  },
  setup() {},
  async mounted() {
    if (store.getters.walletId.length > 0) {
      this.updateNfts();
    }
  },
  props: ["id"],
  methods: {
    async updateNfts() {
      const apiKey = "NXc3nAVIhz-03JtVYgcMm0NmnWnlLR50";
      const web3 = createAlchemyWeb3(
        `https://eth-rinkeby.alchemyapi.io/v2/${apiKey}`
      );

      const ownerAddr = store.state.walletId;
      const nfts = await web3.alchemy.getNfts({
        owner: ownerAddr,
      });

      // valid contract ids
      let validContracts = ["0x8378c0efe5c5b2e7df86a5585a64afb09309d64d"];

      // Print owner's wallet address:
      console.log("fetching NFTs for address:", ownerAddr);
      console.log("...");

      // Print total NFT count returned in the response:
      console.log("number of total found:", nfts.totalCount);
      console.log("...");

      // Print contract address and tokenId for each NFT:
      let coll = [];
      for (const nft of nfts.ownedNfts) {
        console.log("contract address:", nft.contract.address);
        if (validContracts.includes(nft.contract.address)) {
          console.log(`match ${JSON.stringify(nft.metadata)}`);
        }
        if (
          validContracts.includes(nft.contract.address) &&
          nft.metadata.attributes &&
          Object.keys(nft.metadata.attributes).length > 1
        ) {
          console.log("!===!");

          console.log("token hex id:", parseInt(nft.id.tokenId, 16));
          console.log(`attrs: ${JSON.stringify(nft)}`);
          coll.push(nft);
        }
      }
      this._collection = coll;
      console.log(`count ${this._collection.length}`);
    },
  },
  watch: {
    "$store.state.walletId": function () {
      console.log(`this.$store.state.walletId`);
      console.log(this.$store.state.walletId);
      this.updateNfts();
    },
  },
};
</script>
<style scoped>
</style>




// 0x8378C0EFe5c5B2E7DF86a5585a64afB09309D64D
// 0x8378C0EFe5c5B2E7DF86a5585a64afB09309D64D