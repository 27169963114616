import * as Tone from "tone";
import InstrumentPart from "@/vaporwave/instrument";

class FX extends InstrumentPart {

    constructor(bars, bank, buffers = null, parent = null) {

        super(bars, `bank_${bank}`, buffers);

        let urls;
        this.bars = bars;

        if (buffers === null) {
            urls = {
                C1: "sweep_up.wav",
                D1: "sweep_down.wav"
            }
        } else {
            urls = {
                C1: buffers.get("C1"),
            }
        }

        this.sampler = new Tone.Sampler({
            urls: urls,
            baseUrl: `/audio/vaporwave/bank_${bank}/`,
            volume: this.volume - 5,
            onload: () => {
                console.log("fx loaded");
            },
        })

        if (parent === null) {
            this.sampler.toDestination();
        } else {
            this.sampler.connect(parent);
        }

        // the bars at which to start sweeps
        //
        this.startBars = [
            { 'bar': 13, 'note': 'D1', length: 2 },
            { 'bar': 16, 'note': 'C1', length: 3 }
        ]

        // for each start bar, offset by 1 (sweeps are N bars long)
        for (var i = 0; i < this.startBars.length; i++) {
            let barInfo = this.startBars[i];
            let part = this.makePart(barInfo);
            this.parts.push(part);
        }

        this.populateSequence();
    }

    barsToSeconds(bpm, length) {
        // in 4/4 there are 4 beats per measure. FX Sweeps last N bars, so N * 4 total beats.
        return ((length * 4) / bpm) * 60;
    }

    makePart(barInfo) {
        let part = [];
        let bar = barInfo.bar + (4 - barInfo.length);
        let beat = 0;
        let sixteenth = 0;

        part.push({
            time: `${bar}:${beat}:${sixteenth}`,
            duration: this.barsToSeconds(this.bpm, barInfo.length),
            note: barInfo.note,
            velocity: 2,
        })

        return part;
    }

}

export default FX;