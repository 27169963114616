const Palette = require('./palette');

module.exports = class VaporwaveColors {

  constructor(_p5) {

    this.palettes = [];
    this.activeP = 0;
    this.p5 = _p5;

    // 0
    this.palettes.push(new Palette(['#F6ADA5', '#FCB8C5', '#C8608A', '#75CAB7', '#78738C']));

    // 1
    this.palettes.push(new Palette(['#B3B1B2', '#50E241', '#FAA0C3']));

    // 2
    this.palettes.push(new Palette(['#F50000', '#F6F80A', '#02F90E', '#4E4FFD', '#060500']));

    // 3
    this.palettes.push(new Palette(['#0058FF', '#00B4FF', '#EDCBFF', '#74B7FF', '#DEF8FD']));

    // 4
    this.palettes.push(new Palette(['#FF829D', '#D5D7D3', '#63E3A5', '#E29DF7']));

    // 5
    this.palettes.push(new Palette(['#B1D680', '#61A1A3', '#DDA5BF', '#FFFFFF', '#000000']));

    // 6
    this.palettes.push(new Palette(['#F4838A', '#FBB9B4', '#E1BBB4', '#BFB5B4', '#ADC4CE', '#6ACDEA', '#6ECEF7']));

    // 7
    this.palettes.push(new Palette(['#1F1F1F', '#5470A3', '#DBD029', '#ED6243', '#F2F6E9']));

    // 8
    this.palettes.push(new Palette(['#8A34CC', '#9D3BCE', '#A53ECE', '#B344D0', '#CC4ED2', '#DA53D3']));

    // 9
    this.palettes.push(new Palette(['#E85297', '#E4282C', '#6EC7E4', '#FCD255', '#5DBE6F']));

    // 10
    this.palettes.push(new Palette(['#0096B8', '#025DAE', '#FCF206', '#FAD5E6', '#FFFFFF']));

    // 11
    this.palettes.push(new Palette(['#17EAD3', '#E376A3', '#6F8898', '#D9EFEE', '#4B8C85', '#CC3E79', '#112327']));

    // 12
    this.palettes.push(new Palette(['#00B4FF', '#49297E', '#9900DD', '#E10086', '#FDFB76']));

    // 13
    this.palettes.push(new Palette(['#D7FDFE', '#E3E8FD', '#EDDCFC', '#F7CDFC']));

    // 14
    this.palettes.push(new Palette(['#93D2C1', '#5FBBDA', '#898FF7', '#B4CFF1', '#EFC2BD', '#F5D1F1']));

    // 15
    this.palettes.push(new Palette(['#DA529A', '#F9F258', '#010405', '#B1F457', '#62D3D1']));

    // 16
    this.palettes.push(new Palette(['#9A2E68', '#D83850', '#E0724E', '#F3DC7A', '#4E9498']));

    // 17
    this.palettes.push(new Palette(['#74F9EF', '#E833DB', '#000000', '#75FBD6', '#D22DF0']));

    // 18
    this.palettes.push(new Palette(['#171149', '#352855', '#A72DC6', '#CB62CB', '#D197B8', '#EDD077']));

    // 19
    this.palettes.push(new Palette(['#4C2F55', '#669677', '#8EC38E', '#A598A8', '#4D3056']));

    // 20
    this.palettes.push(new Palette(['#A1CEFB', '#8A95E2', '#A88DF8', '#BB78E1', '#ED73D0']));

    this.activeP = Math.floor(Math.random() * 10);
  }

  active() {
    return this.palettes[this.activeP];
  }

  randomFrom() {
    let active = this.active();
    let r = Math.floor(this.p5.random(active.colors.length));
    return this.p5.color(active.colors[r]);
  }

}