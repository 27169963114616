import * as Tone from "tone";
import { markRaw } from "vue";
import Generator from "@/utilities/markov";
import LoopInstrumentPart from "@/vaporwave/loopInstrument";

class Loops extends LoopInstrumentPart {
    constructor(bars, buffer, parent = null) {

        super(bars, buffer);
        console.log(`buffer is ${buffer}!`);

        this.player = new Tone.Player({
            url: buffer,
            onload: () => {
                console.log(`loop buffer loaded`);
            }
        })
        this.player.loop = true;

        if (parent === null) {
            this.player.toDestination();
        } else {
            //const panner = new Tone.Panner(-0.1).connect(parent);
            //this.player.connect(panner);
            this.player.connect(parent);
        }

        let a = [
            { start: "16:0:0", end: "24:0:0" },
            // { start: "16:0:0", end: "32:0:0" },
            { start: "40:0:0", end: "64:0:0" }
        ]

        this.parts = [a];
        this.populateSequence();
    }
}

export default Loops;