import * as Tone from "tone";
import InstrumentPart from "@/vaporwave/instrument";

class FX extends InstrumentPart {

    constructor(bars, bank, buffers = null, parent = null) {

        super(bars, `bank_${bank}`, buffers);

        let urls;

        if (buffers === null) {
            urls = {
                C1: "sweep_up.wav",
            }
        } else {
            urls = {
                C1: buffers.get("C1"),
                D1: buffers.get("D1"),
                E1: buffers.get("E1"),
                F1: buffers.get("F1"),
                G1: buffers.get("G1"),
                A1: buffers.get("A1"),
            }
        }

        this.sampler = new Tone.Sampler({
            urls: urls,
            baseUrl: `/audio/vaporwave/bank_${bank}/`,
            volume: this.volume - 5,
            onload: () => {
                console.log("fx loaded");
            },
        })

        let shift = new Tone.PitchShift({
            pitch: -12,
            wet: 0.9
        })
        this.sampler.connect(shift);

        if (parent === null) {
            shift.toDestination();
        } else {
            shift.connect(parent);
        }

        // the bars at which to start sweeps
        //
        this.startBars = [
            { 'bar': 4, 'note': 'E1', length: 2 },
            { 'bar': 8, 'note': 'D1', length: 3 }
        ]

        const pingPong = new Tone.PingPongDelay("16n", 0.8).toDestination();
        shift.connect(pingPong);

        let allNotes = ['C1', 'D1', 'E1', 'F1', 'G1', 'A1']
        for (let i = 0; i < 20; i++) {
            let rnote = allNotes[Math.floor(Math.random() * allNotes.length)];
            console.log(`note! ${rnote}`);
            let part = [];
            let bar = (i * 4) + 8;
            part.push({
                time: `${bar}:${0}:${0}`,
                duration: '2n',
                note: rnote,
                velocity: 2,
            });
            this.parts.push(part);
        }

        this.populateSequence();
    }

    barsToSeconds(bpm, length) {
        // in 4/4 there are 4 beats per measure. FX Sweeps last N bars, so N * 4 total beats.
        return ((length * 4) / bpm) * 60;
    }

    makePart(barInfo) {
        let part = [];
        let bar = barInfo.bar + (4 - barInfo.length);
        let beat = 0;
        let sixteenth = 0;

        part.push({
            time: `${bar}:${beat}:${sixteenth}`,
            duration: this.barsToSeconds(this.bpm, barInfo.length),
            note: barInfo.note,
            velocity: 2,
        })

        return part;
    }

}

export default FX;