<template>
  <h1 class="title is-family-monospace mx-6 my-5">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li><a href="/">Record Club</a></li>
        <li class="is-active">
          <a href="#">Collections</a>
        </li>
      </ul>
    </nav>
  </h1>
  <div class="container columns mx-6 my-5">
    <div class="column is-full">
      <div class="bottom-space">
        <div class="is-family-monospace">
          <div class="container columns">
            <div class="column is-one-half">
              <div class="content">
                <div style="flex-wrap: wrap" class="tile is-ancestor">
                  <div
                    class="tile px-2 py-3"
                    v-for="image in collections"
                    :key="image.id"
                  >
                    <div class="box">
                      <figure class="image is-128x128">
                        <img v-bind:src="image.src" />
                      </figure>
                      <h5>{{ image.name }}</h5>
                      <p v-if="image.avail === false">[roadmap]</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="block"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
export default {
  data() {
    return {
      collections: [
        {
          id: 1,
          avail: true,
          src: "images/bezos/bezos_1.png",
          name: "Vaporwave",
        },
        { id: 2, avail: false, src: "images/default.png", name: "Math Rock" },
        { id: 3, avail: false, src: "images/default.png", name: "Dream Pop" },
        { id: 4, avail: false, src: "images/default.png", name: "Ambient" },
        { id: 5, avail: false, src: "images/default.png", name: "Black Midi" },
        { id: 6, avail: false, src: "images/default.png", name: "Krautrock" },
      ],
    };
  },
  name: "Collections",
  setup() {},
  methods: {},
};
</script>
<style scoped>
</style>