<template>
  <div class="container columns m-1">
    <div class="column is-one-third">
      <div class="box bottom-space">
        <figure class="image">
          <div class="d-flex justify-content-center" id="p5Canvas"></div>
        </figure>
      </div>
    </div>
    <div class="column is-one-half my-4">
      <h1 id="artist" class="title is-family-monospace">{{ artistText }}</h1>
      <h1 id="album" class="subtitle is-family-monospace">{{ albumText }}</h1>
      <div class="card pt-2 pb-5">
        <div class="content">
          <ul id="example-1">
            <li class="level" v-for="track in trackList" :key="track.key">
              {{ track.key }}. {{ track.name }}
              <o-button
                class="mr-5"
                id="hide-seen"
                @click="startAudio(track.key)"
                >{{ buttonText }}</o-button
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="card mt-5">
        <div class="card-content">
          <div class="content">
            <h5 class="title is-5">Description</h5>
            <p>{{ albumTextDescription }}</p>
          </div>
        </div>
      </div>
      <div class="card mt-5">
        <div class="card-content">
          <div class="content">
            <h5 class="title is-5">What they're saying</h5>
            <div
              class="mt-3 has-background-white"
              v-for="review in reviews"
              :key="review.key"
            >
              <div class="content">
                <blockquote>{{ review }}</blockquote>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Tone from "tone";
import { ref } from "vue";
import { useRoute } from "vue-router";

// tracks
import GradientTripping from "@/vaporwave/albums/bezos/1/track";
import ASoCiAlMeDiANeOnPoP from "@/vaporwave/albums/bezos/2/track";
import MallsoftDreams from "@/vaporwave/albums/bezos/3/track";
import ROMDealer from "@/vaporwave/albums/bezos/4/track";
import KonnichiwaOpenSkies from "@/vaporwave/albums/bezos/5/track";

import { createAlchemyWeb3 } from "@alch/alchemy-web3";
import { store } from "../store";
const axios = require("axios").default;

export default {
  // Using the composition API to set our component variables
  data() {
    return {
      currentTrack: 1,
      trackList: [],
      reviews: [],
    };
  },
  /*created() {
    console.log("created " + this.currentTrack); // 1
  },*/
  setup() {
    // set BPM

    const route = useRoute();
    console.log(`route2! ${route.query.id}`);

    Tone.Transport.bpm.value = 120;
    const reverb = new Tone.Reverb({
      decay: 30,
      preDelay: 0.0,
      wet: 0.5,
    }).toDestination();

    const reverb2 = new Tone.Reverb({
      decay: 30,
      preDelay: 0.0,
      wet: 0.5,
    }).toDestination();

    let bank = Math.floor(Math.random() * 5) + 1;
    let kit = Math.floor(Math.random() * 6) + 1;

    /*
Gradient Tripping
ASoCiAl MeDiA NeOn PoP
Mallsoft Dreams
ROM Dealer
Konnichiwa (Open Skies)
    */

    let tracks = [];
    if (route.params.id === "bezos") {
      /*let track1 = new GradientTripping(kit, bank);
      track1.load(reverb);
      tracks.push(track1);
      Tone.Transport.bpm.value = 105;
      let track2 = new ASoCiAlMeDiANeOnPoP(kit, bank);
      track2.load(reverb);
      tracks.push(track2);
      //Tone.Transport.bpm.value = 110;
      let track3 = new MallsoftDreams(kit, bank);
      track3.load(reverb2);
      tracks.push(track3);
      /*Tone.Transport.bpm.value = 112;
      let track4 = new ROMDealer(kit, bank);
      track4.load(reverb2);
      tracks.push(track4);
      Tone.Transport.bpm.value = 77;*/
      let track5 = new KonnichiwaOpenSkies(kit, bank);
      track5.load(reverb2);
      tracks.push(track5);
      Tone.Transport.bpm.value = 103;
    }

    console.log(`track count ${tracks.length}`);

    let engineStarted = false;
    let isPlaying = ref(false);
    let buttonText = ref("Play");

    let artistText = ref("");
    let albumText = ref("");
    let albumTextDescription = ref("Some text");
    let id;

    console.log(`engineStarted ${engineStarted}`);

    var sketch; // = require("../assets/p5/1/sketch");

    console.log("setup");

    return {
      id,
      artistText,
      albumText,
      albumTextDescription,
      sketch,
      isPlaying,
      buttonText,
      engineStarted,
      tracks,
      //,
      bank,
      kit,
    };
  },
  async mounted() {
    console.log("mounted");
    console.log("audio is ready");
    console.log(`wallet id ${JSON.stringify(this.$store.state)}`);
    console.log(`wallet count ${this.$store.state.count}`);

    //this.currentTrack = 1;

    this.buttonText = "Play";
    this.id = this.$route.params.id;
    let tokenId = this.$route.query.token;
    let contractAddress = "0x8378C0EFe5c5B2E7DF86a5585a64afB09309D64D"; //"0x0dbb944846d82dc225b583285d4105d56cae9048";

    if (store.getters.walletId.length > 0) {
      this.loadMetadata(contractAddress, tokenId);
    }

    //load if we're connected

    console.log(`path ${`@/assets/p5/${this.id}/sketch`}`);
    console.log(`tttthis.$store.state.walletId`);
    console.log(`ttthis ${this.$store.state.walletId}`);

    this.sketch = require(`@/assets/p5/${this.id}/sketch`);
    const P5 = require("p5");
    this.p5 = new P5(this.sketch.main);

    // load from contract
    this.artistText = "Loading...";
    this.albumText = "";
    this.albumTextDescription = "";
  },
  props: ["id"],
  methods: {
    async loadMetadata(contractAddress, tokenId) {
      const apiKey = "NXc3nAVIhz-03JtVYgcMm0NmnWnlLR50";
      const web3 = createAlchemyWeb3(
        `https://eth-rinkeby.alchemyapi.io/v2/${apiKey}`
      );

      console.log(`contractAddress ${contractAddress}`);

      const ownerAddr = store.state.walletId;
      const nftMetadata = await web3.alchemy.getNftMetadata({
        contractAddress: contractAddress,
        tokenId: tokenId,
      });

      console.log(`nft meta ${JSON.stringify(nftMetadata)}`);

      this.albumText = nftMetadata.title;
      this.artistText = nftMetadata.metadata.attributes.artist;
      this.albumTextDescription = nftMetadata.description;
      this.reviews = nftMetadata.metadata.reviews;
      for (var i = 0; i < nftMetadata.metadata.tracks.length; i++) {
        let trk = nftMetadata.metadata.tracks[i];
        trk.key = i + 1;
        this.trackList.push(trk);
      }

      // load art
      let artUrl = nftMetadata.metadata.artMetadataUrl;
      console.log(`getting art url ${nftMetadata.metadata.artMetadataUrl}`);
      let res = await axios.get(nftMetadata.metadata.artMetadataUrl);
      //console.log(`Boooo ${JSON.stringify(res)}`);
    },
    onRepeat(time) {
      let pos = Tone.Transport.position.split(`:`);
      let beat = pos[0];
      console.log(`${pos[0]} ${pos[1]} ${pos[2].split(`.`)[0]}`);
      let track = this.tracks[this.currentTrack - 1];

      track.play(pos, time);
      ``;
    },
    async startAudio(id) {
      this.currentTrack = 1; //id;
      Tone.Transport.bpm.value = this.tracks[this.currentTrack - 1].bpm;
      console.log(`PPQ ${Tone.Transport.blockTime}`);
      console.log(`Playing ${id}`);

      this.p5.togglePlaying();
      if (this.engineStarted === false) {
        await Tone.start();

        Tone.Transport.scheduleRepeat(this.onRepeat, "16n");

        this.engineStarted = true;
      }

      let g = Tone.Time("100:0:0");
      console.log(g.toSeconds());

      this.isPlaying = !this.isPlaying;
      this.buttonText = this.isPlaying ? "Stop" : "Play";

      if (this.isPlaying === false) {
        console.log("stopped");
        this.isPlaying = false;
        Tone.Transport.cancel();

        for (const t of this.tracks) {
          for (const loop of t.loops) {
            loop.player.stop();
          }
        }

        return;
      }

      Tone.Transport.start();
    },
  },
};
</script>
