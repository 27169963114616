<template>
  <div class="container columns m-1">
    <div class="column is-one-half my-4">
      <div class="my-5">
        <a id="mydiv" ref="myid">Hi</a>
      </div>
    </div>
  </div>
</template>

<script>
import * as Tone from "tone";
import { useRoute } from "vue-router";
import GradientTripping from "@/vaporwave/albums/bezos/1/track";
import ASoCiAlMeDiANeOnPoP from "@/vaporwave/albums/bezos/2/track";
import MallsoftDreams from "@/vaporwave/albums/bezos/3/track";
import ROMDealer from "@/vaporwave/albums/bezos/4/track";
import KonnichiwaOpenSkies from "@/vaporwave/albums/bezos/5/track";
var audioBufferToWav = require("audiobuffer-to-wav");

export default {
  // Using the composition API to set our component variables
  setup() {
    // set BPM

    Tone.Transport.bpm.value = 20;

    let engineStarted = false;

    const route = useRoute();
    let id = route.query.id;
    let name = route.query.name;
    let player = new Tone.Player().toDestination();
    let buffer;
    let trackTimeInSeconds;

    let json = JSON.parse(route.query.data);
    console.log(`JSON ${JSON.stringify(json.drums)}`);

    /* 
    preload all buffers
    */

    let audio = json.audio;
    let bank = audio.bank;
    let kit = audio.kit;

    let track;
    console.log(`trackName ${name}`);
    if (name === "bezos1") {
      track = new GradientTripping(kit, bank);
    } else if (name === "bezos2") {
      track = new ASoCiAlMeDiANeOnPoP(kit, bank);
    } else if (name === "bezos3") {
      track = new MallsoftDreams(kit, bank);
    } else if (name === "bezos4") {
      track = new ROMDealer(kit, bank);
    } else {
      track = new KonnichiwaOpenSkies(kit, bank);
    }

    // we use this to pass back to puppeteer
    let metadata = {};

    return {
      id,
      name,
      engineStarted,
      buffer,
      player,
      bank,
      kit,
      audio,
      metadata,
      track,
    };
  },
  async mounted() {
    this.anchor = this.$refs["myid"];

    window.getMetadata = this.getMetadata;
    window.getBlob = this.getBlob;

    setTimeout(() => {
      console.log("scheduling!");

      const renderingPromise = Tone.Offline(({ transport }) => {
        const eq = new Tone.BiquadFilter({
          type: "lowshelf",
          frequency: 20,
          gain: -5,
        });
        eq.toDestination();

        const loweq = new Tone.BiquadFilter({
          type: "highshelf",
          frequency: 2500,
          gain: -5,
        }).connect(eq);

        const comp = new Tone.Compressor(-30, 3).connect(loweq);

        const reverb = new Tone.Reverb({
          decay: 35,
          preDelay: 0.0,
          wet: 0.4,
        }).connect(comp);

        transport.bpm.value = this.track.bpm;
        console.log(`transport BEE PEE EMM ${transport.bpm.value}`);
        console.log(`PPQ ${transport.blockTime}`);

        this.track.load(reverb);

        // set metadata to pass back to puppeteer
        this.metadata = {
          tracks: {
            bass: this.track.bass.parts,
            lead: this.track.lead.parts,
            pluck: this.track.pluck.parts,
            drums: this.track.drums.parts,
          },
        };

        function handleToneTransportTick(time, context, track) {
          let pos = context.transport.position.split(`:`);
          let beat = parseInt(pos[1]);
          let bar = parseInt(pos[0]);

          //console.log(context.transport.position);
          let sixteenth = Math.round(parseFloat(pos[2]));
          if (sixteenth === 4) {
            beat++;
            if (beat === 4) {
              beat = 0;
              bar++;
            }
            sixteenth = 0;
          }

          /*console.log(`our pos ${pos[0]} ${beat} ${sixteenth}`);
          console.log(`seconds ${context.transport.seconds}`);
          console.log(`pos ${context.transport.position}`);*/

          track.play(pos, time);
        }

        transport.scheduleRepeat((time) => {
          //someFunc();
          handleToneTransportTick(time, transport.context, this.track);
        }, "16n");

        transport.start(0.2);

        //return this.player.ready;
      }, 240); // 3 minutes max track length

      // set the buffer when it's done
      renderingPromise.then((buffer) => {
        this.buffer = buffer;
        this.player.buffer = buffer;
      });
      renderingPromise.then(() => {
        this.startAudio();
        console.log("renderingPromise done");
      });
    }, 5000); // need to give it time to load everything...

    console.log("starting");
  },
  props: ["id"],
  methods: {
    getMetadata() {
      return this.metadata;
    },
    getBlob() {
      return this.anchor.href;
    },
    async startAudio() {
      console.log("starting audio " + this.buffer);
      await Tone.start();
      console.log(`this.player ${this.player}`);

      this.player.start();

      console.log("audio started!");

      try {
        var wav = audioBufferToWav(this.player.buffer);
        console.log("wav!");
        console.log(wav);
        var blob = new window.Blob([new DataView(wav)], {
          type: "audio/wav",
        });
        var url = window.URL.createObjectURL(blob);
        this.anchor.href = url;
        this.anchor.download = `${this.name}.wav`;
        this.anchor.click();
        window.URL.revokeObjectURL(url);
        console.log(`downloading! ${this.name}`);
      } catch (error) {
        console.log("error !" + error);
      }
    },
  },
};
</script>

<style scoped>
</style>
