import Track from "@/classes/track";
import Drums from "@/vaporwave/albums/bezos/1/drums";
import Bass from "@/vaporwave/albums/bezos/1/bass";
import Lead from "@/vaporwave/albums/bezos/1/lead";
import FX from "@/vaporwave/albums/bezos/1/fx";
import Harmony from "@/vaporwave/albums/bezos/1/harmony";
import Pluck from "@/vaporwave/albums/bezos/1/pluck";
import Loops from "@/vaporwave/albums/bezos/1/loops";
import Chords from "@/vaporwave/albums/bezos/1/chords";
import * as Tone from "tone";

class GradientTripping extends Track {

    constructor(kit, bank) {
        let k = Math.floor(Math.random() * 30) + 1;
        super(kit, k, 'bezos1');
        console.log(`loaded bank ${k}`)
        this.bpm = 105;
        this.useOdds = true;
    }

    load(parent = null) {
        Tone.Transport.bpm.value = this.bpm;
        this.drums = new Drums(4, this.kit, null, this.drumBuffers, parent);
        this.bass = new Bass(8, this.bank, this.bassBuffers, parent);
        this.lead = new Lead(8, this.bank, this.leadBuffers, parent);
        this.fx = new FX(4, this.bank, this.fxBuffers, parent);
        this.harmony = new Harmony(8, this.bank, this.pluckBuffers, parent);
        this.pluck = new Pluck(8, this.bank, this.pluckBuffers, parent);
        this.chords = new Chords(8, this.bank, this.pluckBuffers, parent);
        this.instrumentParts = [this.drums, this.chords, this.bass, this.pluck, this.harmony, this.lead];
        this.loops = [];

        for (const loop of this.loopBuffers) {
            let newLoop = new Loops(8, loop, parent);
            this.loops.push(newLoop);
        }
    }

}

export default GradientTripping;