let p5;
let playing = false;
const Tri = require('./tri');
const VaporwaveColors = require('./vaporwaveColors');
const tinycolor = require("tinycolor2");
let canvasWidth = document.getElementById('p5Canvas').offsetWidth;
var metadata;

export function setData(data, autoplay = false) {
  metadata = data.properties.properties.art;
  playing = autoplay;
}

export function main(_p5) {

  p5 = _p5;

  let bgc;
  let isLightBG = false;
  let bgColor;

  var triangles = [];
  var count = metadata ? metadata.objectCount : 5;
  console.log(`count: ${count}`)
  var a = 0;
  var s = 0;
  let colors = new VaporwaveColors(p5);
  if (metadata) {
    colors.activeP = metadata.palette_id;
  }

  bgColor = p5.color(colors.active().bg1);
  isLightBG = tinycolor(bgColor).isLight();
  let titleColor = p5.color(colors.active().bg2);

  console.log("getting here ok");

  p5.preload = _ => {

  }

  // NOTE: Set up is here   
  p5.setup = _ => {
    var canvas = p5.createCanvas(canvasWidth, canvasWidth, p5.WEBGL);
    canvas.parent("p5Canvas");
    p5.pixelDensity(p5.displayDensity());
    p5.frameRate(60);
    p5.background(255);
    p5.ambientLight(51, 102, 126);
    p5.colorMode(p5.RGB, 255, 255, 255);
    for (var n = 0; n < count; n++) {
      if (metadata) {
        let data = metadata.objects[n];
        triangles.push(new Tri(p5, colors, data, canvasWidth));
      } else {
        triangles.push(new Tri(p5, colors, null, canvasWidth));
      }
    }
  }

  p5.windowResized = _ => {
    console.log("cool");
    canvasWidth = document.getElementById('p5Canvas').offsetWidth;
    p5.resizeCanvas(canvasWidth, canvasWidth);
  }

  p5.togglePlaying = _ => {
    playing = !playing;
  }

  // NOTE: Draw is here
  p5.draw = _ => {
    p5.background(255);
    p5.lights();
    p5.background(bgColor);
    for (var i = 0; i < count; i++) {
      let t = triangles[i];
      if (playing) {
        t.update();
      }

      t.draw();
    }


    a += 0.01;
    s = p5.sin(a);
  }
} 
