
module.exports = class Tri {

    constructor(_p5, colors, data) {

        this.p5 = _p5;
        this.colors = colors;
        this.centerX = this.p5.width / 2;
        this.centerY = this.p5.height / 2;
        this.rotationDegrees = data ? data.rotationDegrees : Math.random(360);
        this.c1 = data ? this.p5.color(data.color1) : this.colors.randomFrom();
        this.c2 = data ? this.p5.color(data.color2) : this.colors.randomFrom();
        this.rAmt = data ? data.rotation_amount : this.p5.random(6) + 1;
        this.sAmount = data ? data.speed : this.p5.random(0.05);
        this.a = 0;
        this.s = 0;
        this.d = true;
        if (this.d) {
            this.rAmt *= -1;
        }
    }

    update() {
        this.rotationDegrees += this.rAmt;
        this.a += this.sAmount;
        this.s = this.p5.sin(this.a);
    }

    draw() {
        let shape = "box";// "triangle";
        let interA = this.p5.lerpColor(this.c1, this.c2, this.s);
        //fill(palettes.get(activeP).colors.get(2));
        this.p5.noStroke();
        this.p5.fill(interA);
        //strokeWeight(2);
        //this.p5.push();
        this.p5.translate(0, 0);
        this.p5.rotateZ(270 * this.p5.PI / 180);
        this.p5.rotateX(this.rotationDegrees * (this.p5.PI / 180));
        this.p5.rotateY(this.rotationDegrees * (this.p5.PI / 180));
        //scale(max(this.s, 0.8));
        this.p5.box(170, 170, 3);
        //this.p5.pop();
    }
}