<template>
  <h1 class="title is-family-monospace mx-6 my-5">FAQ</h1>
  <div class="container columns mx-6 my-5">
    <div class="column is-full">
      <div class="bottom-space">
        <div class="is-family-monospace">
          <div class="box container columns">
            <div class="column is-one-half">
              Give me fuel give me fire give me that which I desire!
              <p>- Metallica</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
export default {
  name: "FAQ",
  setup() {},
  methods: {},
};
</script>
<style scoped>
</style>