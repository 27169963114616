import * as Tone from "tone";
import { markRaw } from "vue";
import Generator from "@/utilities/markov";
import LoopInstrumentPart from "@/vaporwave/loopInstrument";

class Loops extends LoopInstrumentPart {
    constructor(bars, buffer, parent = null) {

        super(bars, buffer);
        console.log(`buffer is ${buffer}!`);

        this.player = new Tone.Player({
            url: buffer
        })
        this.player.loop = true;
        this.player.volume.value = 2;

        if (parent === null) {
            this.player.toDestination();
        } else {
            const panner = new Tone.Panner(-0.1).connect(parent);
            this.player.connect(panner);
        }

        let includeBars = [0, 2, 4, 5, 7];
        let a = [];
        for (const bar of includeBars) {
            let start = `${bar * this.bars}:0:0`;
            let end = `${(bar * this.bars) + this.bars}:0:0`;
            let pos = {
                start: start,
                end: end
            }
            a.push(pos);
        }
        console.log(`loops ${JSON.stringify(a)}`);
        this.parts = [a];
        this.populateSequence();
    }
}

export default Loops;