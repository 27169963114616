import * as Tone from "tone";
import { markRaw } from "vue";
import Generator from "@/utilities/markov";
//import { InstrumentPart } from "./instrument";
import InstrumentPart from "@/vaporwave/instrument";

class Lead extends InstrumentPart {
    constructor(bars, bank, buffers = null, parent = null) {

        super(bars, `bank_${bank}`, buffers);

        this.midi = require('@/assets/json/Konnichiwa (Open Skies)/Lead.json');

        let source = buffers === null ? "lead.wav" : buffers.get("C3");
        console.log(`buffers ${buffers}`)
        console.log(`source ${source}`);

        this.sampler = new Tone.Sampler({
            urls: {
                C3: buffers.get("C3"),
                C4: buffers.get("C4"),
                C5: buffers.get("C5"),
            },
            baseUrl: `/audio/vaporwave/bank_${bank}/`,
            volume: this.volume,
            onload: () => {
                console.log("lead loaded");
            },
        })

        if (parent === null) {
            this.sampler.toDestination();
        } else {
            const panner = new Tone.Panner({
                pan: -0.8,
                channelCount: 2
            }).connect(parent);
            this.sampler.connect(panner);
        }

        let part = this.partFromMidi(this.midi);
        let markovPart = this.markovPart(part, 0);

        let include = [0, 1];
        let markovIncludes = [6];
        console.log(`populating lead ${JSON.stringify(part)}`);
        for (let i = 0; i < 8; i++) {
            if (include.includes(i) === true) {
                let p = this.copyPart(part, bars * i);
                this.parts.push(p);
            }
            if (markovIncludes.includes(i) === true) {
                console.log(`bars * i ${bars * i}`);
                let p = this.copyPart(markovPart, bars * i);
                this.parts.push(p);
            }

            console.log(`lead i ${bars * i}`);
        }

        this.populateSequence();

        console.log(`lead sequence: ${JSON.stringify(this.sequence)}`);
    }

}

export default Lead;