module.exports = class RCBox {

  constructor(tx, ty, tw, th, _p5, _colors, _maxSize, _grid) {
    this.p5 = _p5;
    this.targetPos = _p5.createVector(tx, ty);
    this.x = tx;
    this.y = ty;
    this.w = tw;
    this.h = th;
    this.targetW = this.w;
    this.targetH = this.h;
    this.c = _colors.randomFrom();
    this.oldColor = this.c;
    this.targetColor = this.c;
    this.easing = 0.01;
    this.targetPercent = 0;
    this.angle = 0;
    this.colors = _colors;
    this.stroked = _p5.int(_p5.random(100)) == 1;
    this.maxSize = _maxSize;
    this.grid = _grid;
  }

  moveTo(newPos) {
    this.targetPos = newPos;
    this.targetColor = this.colors.randomFrom();
    this.oldColor = this.c;
    this.targetPercent = 0;
    let side = this.p5.random(this.maxSize);
    let snappedSide = this.p5.round(side / this.grid) * this.grid;
    this.targetW = snappedSide;
    this.targetH = this.targetW;
  }

  update() {
    this.angle += 0.001;
    let targetX = this.targetPos.x;
    let dx = targetX - this.x;
    this.x += dx * this.easing;
    let targetY = this.targetPos.y;
    let dy = targetY - this.y;
    this.y += dy * this.easing;

    let dw = this.targetW - this.w;
    this.w += dw * this.easing;

    let dh = this.targetH - this.h;
    this.h += dh * this.easing;

    this.targetPercent += 0.005;

    let lerp = this.p5.lerpColor(this.oldColor, this.targetColor, this.targetPercent);

    if (this.targetPercent <= 0) {
      this.targetPercent = 0;
      this.oldColor = this.c;
      this.targetColor = this.c;
      this.targetPercent = 1;
    } else {
      this.c = lerp;
    }
  }

  draw() {

    if (this.stroked) {
      this.p5.stroke(this.c);
      this.p5.noFill();
    } else {
      this.p5.noStroke();
      this.p5.fill(this.c);
    }

    //this.p5.rect(0, 0, 200, 200);

    this.p5.push();
    this.p5.translate(this.x, this.y);
    this.p5.plane(this.w, this.h, 2, 2);
    this.p5.pop();
  }

}