import { createRouter, createWebHistory } from 'vue-router'
import Home from '../components/Home.vue'
import Main from '../components/Main.vue'
import About from '../components/About.vue'
import Vaporwave from '../components/Vaporwave.vue'
import FAQ from '../components/FAQ.vue'
import Token from '../components/Token.vue'
import VaporPlayer from '../components/VaporPlayer.vue'
import Album from '../components/Album.vue'
import MintVapor from '../components/MintVapor.vue'
import MintVaporAudio from '../components/MintVaporAudio.vue'
import Collections from '../components/Collections.vue'

const routes = [
  {
    path: '/collection/:id',
    name: 'Home',
    component: Home,
    props: true
  },
  {
    path: '/collection/1/album/:id',
    name: 'Album',
    component: Album,
    props: true
  },
  {
    path: '/mint/album/:id',
    name: 'MintVapor',
    component: MintVapor,
    props: true
  },
  {
    path: '/',
    name: 'Main',
    component: Main,
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQ,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/vaporwave',
    name: 'Vaporwave',
    component: Vaporwave,
  },
  {
    path: '/collections',
    name: 'Collections',
    component: Collections,
  },
  {
    path: '/mint/audio',
    name: 'MintVaporAudio',
    component: MintVaporAudio,
  }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
export default router
