import * as Tone from "tone";
import { markRaw } from "vue";
import { Note, Scale, Chord, ChordType, Key, Progression } from "@tonaljs/tonal";
import { note, interval } from "@tonaljs/core";
import Generator from "@/utilities/markov";

export default class InstrumentPart {

    // takes bars - length in bars for instument
    // bank - location of samples (bank_1 or kit_1 etc)
    constructor(bars, bank, buffers = null) {
        this.volume = -10;
        this.sampler;
        this.sequence = [];
        this.bpm = Tone.Transport.bpm.value;
        console.log(`instrument bpm ${this.bpm}`);
        this.parts = [];
        this.useOdds = false;
    }

    partFromOdds(odds, i, prt, markovPart = null) {

        let r = Math.floor(Math.random() * 100);

        if (r < odds[0]) {
            let p = this.copyPart(prt[0], this.bars * i);
            this.parts.push(p);
        }

        if (odds.length === 2) {
            if (r >= odds[0] && r < odds[0] + odds[1]) {
                if (markovPart != null) {
                    let p = this.copyPart(markovPart, this.bars * i);
                    this.parts.push(p);
                } else {
                    let p = this.copyPart(prt[1], this.bars * i);
                    this.parts.push(p);
                }

            }
        }

        if (odds.length === 3) {
            if (r >= odds[0] && r >= odds[1] && r < odds[0] + odds[1] + odds[2]) {
                let p = this.copyPart(prt[2], this.bars * i);
                this.parts.push(p);
            }
        }

    }

    markovPart(existingPart, newStart) {

        let markovCopy = [];

        var noteGenerator = new Generator(2, existingPart.length);
        for (var i = 0; i < existingPart.length; i++) {
            // add note to our markov chain
            noteGenerator.feed(existingPart[i].note);
        }

        for (var i = 0; i < existingPart.length; i++) {
            let part = existingPart[i];
            let position = part.time.split(":");
            let bar = parseInt(position[0]);
            let beat = position[1];
            let sixteenth = position[2];
            let newTime = `${bar + newStart}:${beat}:${sixteenth}`;

            markovCopy.push({
                time: newTime,
                note: noteGenerator.generate(),
                duration: part.duration,
                velocity: part.velocity
            });
        }

        return markovCopy;
    }

    // preroll is in 16ths
    // new start is in bars
    copyPart(existingPart, newStart, preroll = 0, crop = false) {
        let copy = [];
        let startBar = 0;
        let endBar = crop === false ? existingPart.length : existingPart.length / 2;
        for (var i = 0; i < endBar; i++) {
            let part = existingPart[i];
            let position = part.time.split(":");
            let bar = parseInt(position[0]);
            if (i === 0) {
                startBar = bar;
            }

            let beat = position[1];
            let sixteenth = position[2];
            let newTime = `${(bar + newStart) - startBar}:${beat}:${sixteenth}`;

            if (preroll !== 0) {
                // convert
                let t = Tone.Time(newTime);
                let pr = Tone.Time("16n");
                let tMillis = t.toSeconds();
                let prMillis = pr.toSeconds();
                let totalPrMS = prMillis * preroll;
                let newMillis = tMillis - totalPrMS;
                newTime = Tone.Time(newMillis).toBarsBeatsSixteenths();

            }
            copy.push({
                time: newTime,
                note: part.note,
                duration: part.duration,
                velocity: part.velocity
            });
        }

        return copy;
    }

    // up, down, step, random
    makeArpeggiatePart(start, length, notes, style = 'up') {

        let part = [];
        let currentPosition = style === 'down' ? notes.length - 1 : 0;;
        let direction = style === 'down' ? 0 : 1;

        // bars
        for (var bar = 0; bar < length; bar++) {
            for (var beat = 0; beat < 4; beat++) {
                for (var sixteenth = 0; sixteenth < 4; sixteenth++) {
                    let newTime = `${bar + start}:${beat}:${sixteenth}`;

                    let vel = 1;
                    if (style === 'random') {
                        vel = Math.random(0.5) + 0.5;
                    } else if (style === 'up') {
                        vel = (currentPosition * 0.2) + 0.5;
                    } else if (style === 'down') {
                        vel = ((notes.length - currentPosition) * 0.2) + 0.5;
                    }

                    part.push({
                        time: `${bar + start}:${beat}:${sixteenth}`,
                        note: notes[currentPosition],
                        duration: "16n",
                        velocity: vel,
                    });

                    if (style === 'up') {
                        if (currentPosition < notes.length - 1) {
                            currentPosition++;
                        } else {
                            currentPosition = 0;
                        }
                    } else if (style === 'down') {
                        if (currentPosition > 1) {
                            currentPosition--;
                        } else {
                            currentPosition = notes.length - 1;
                        }
                    } else if (style === 'random') {
                        currentPosition = Math.floor(Math.random() * notes.length);
                    }

                }
            }
        }

        return part;
    }

    harmonyPart(melodyPart, start, key, major, octave, seventh = false) {

        let part = [];
        let keyInfo;
        let chords;
        let notes = [];
        for (const element of melodyPart) {
            notes.push(element.note);
        }

        let detected = Chord.detect(notes);

        if (major) {
            keyInfo = Key.majorKey(key);
            chords = keyInfo.chords;
        } else {
            keyInfo = Key.minorKey(key);
            chords = keyInfo.natural.chords;
        }

        let randChord = chords[Math.floor(Math.random() * chords.length)];

        console.log(`chords ${JSON.stringify(chords)}`);
        console.log(`rand chord ${randChord}`);

        for (var bar = 0; bar < 4; bar++) {

            let randChord = chords[Math.floor(Math.random() * chords.length)];
            let chord = randChord;

            for (var beat = 0; beat < 4; beat++) {

                let third = Math.floor(Math.random() * 10) === 0;

                // if beat is 0 play tonic
                if (beat === 0) {
                    chord = chords[0];
                } else if (beat === 2) {
                    chord = randChord;
                } else if (beat === 3) {
                    let rc = chords[Math.floor(Math.random() * chords.length)];
                    chord = rc;
                } else {
                    continue;
                }

                let c = Chord.get(chord);
                let notes = c.notes;
                let notePosition = 0;
                for (const note of notes) {
                    if (seventh === false && notePosition === 3) {
                        continue;
                    }
                    console.log(note);
                    part.push({
                        time: `${bar + start}:${beat}:${0}`,
                        note: `${note}${octave}`,
                        duration: "8n",
                        velocity: 0.8,
                    });
                    notePosition++;
                }

                console.log(`chord ${chord} ${JSON.stringify(c)}`);

                // get notes

                for (var sixteenth = 0; sixteenth < 4; sixteenth++) {

                }
            }
        }

        return part;
    }

    transposePart(existingPart, newStart, transpose) {
        let copy = [];
        let startBar = 0;
        for (var i = 0; i < existingPart.length; i++) {
            let part = existingPart[i];
            let position = part.time.split(":");
            let bar = parseInt(position[0]);
            if (i === 0) {
                startBar = bar;
            }

            let beat = position[1];
            let sixteenth = position[2];
            let newTime = `${(bar + newStart) - startBar}:${beat}:${sixteenth}`;
            let newNote = this.transposeNote(part.note, transpose);

            copy.push({
                time: newTime,
                note: newNote,
                duration: part.duration,
                velocity: part.velocity
            });
        }

        return copy;
    }

    partFromMidi(midiPart) {

        let part = [];
        for (var i = 0; i < midiPart.length; i++) {
            let midi = midiPart[i];
            let t = Tone.Time(midi.seconds);
            let converted = t.toBarsBeatsSixteenths().split(':');
            let bar = converted[0];
            let beat = converted[1];
            let sixteenth = parseInt(converted[2].split('.')[0]);
            let extra = parseFloat(converted[2].split('.')[1]);

            //midi.barsBeatsSixteenths = converted;
            let d = {
                time: `${bar}:${beat}:${sixteenth}`,
                note: midi.name,
                duration: midi.duration,
                velocity: midi.velocity
            };
            part.push(d);

        }

        return part;
    }

    makeRandomPart(start, length) {

    }

    populateSequence() {
        for (var i = 0; i < this.parts.length; i++) {
            let part = this.parts[i];

            for (var j = 0; j < part.length; j++) {
                this.sequence.push(part[j]);
            }
        }

    }

    triggerSample(currentPosition, time, transpose = null) {
        let bar = parseInt(currentPosition[0]);
        let beat = parseInt(currentPosition[1]);
        //let sixteenth = currentPosition[2].split(`.`)[0];


        let sixteenth = Math.round(parseFloat(currentPosition[2]));
        if (sixteenth === 4) {
            beat++;
            sixteenth = 0;
            if (beat === 4) {
                beat = 0;
                bar++;
            }
        }

        for (var i = 0; i < this.sequence.length; i++) {
            let component = this.sequence[i];
            let componentPos = component.time.split(`:`);
            let testBar = componentPos[0];
            let testBeat = componentPos[1];
            let testSixteenth = componentPos[2];
            if (
                testBar === `${bar}` &&
                testBeat === `${beat}` &&
                testSixteenth === `${sixteenth}`
            ) {
                let note = transpose === null ? component.note : Note.transpose(component.note, transpose);
                //console.log(`note: ${note}`);
                this.sampler.triggerAttackRelease(
                    note,
                    component.duration,
                    time,
                    component.velocity
                );
            }
        }
    }

    transposeNote(note, by) {
        return Note.transpose(note, by);
    }

}