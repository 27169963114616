var MAX_MIX = 0.2;
var TRESH = 0.4;

module.exports = class ImageSlice {

  constructor(x, y, w, h, pixelColor, _p5) {
    this.p5 = _p5;
    this.pixelColor = pixelColor;
    this.x = _p5.float(x);
    this.y = _p5.float(y);
    this.w = _p5.float(w);
    this.h = _p5.float(h);
    this.angle = 0;
    let clockwise = _p5.floor(_p5.random(2)) == 1;
    this.rate = clockwise ? -_p5.random(0.01) : _p5.random(0.01);
    //_p5.rotateZ(_p5.sin(this.angle));

    //console.log(`${this.x}, ${this.y}`);
  }

  render() {
    // a random shade of grey to mix with
    let n = this.p5.color(this.p5.random(255));
    let result = this.p5.lerpColor(this.pixelColor, n, this.p5.random(MAX_MIX));
    let c = this.p5.random(1) <= TRESH ? result : this.pixelColor;
    //
    //translate(0, 0);
    //translate(width / 2 - 200, height / 2 - 200);
    this.p5.push();
    this.p5.translate(this.x + this.w / 2, this.y + this.h / 2);
    //console.log(this.x);
    this.p5.fill(c);
    this.p5.noStroke();
    //this.p5.rotateZ(this.p5.sin(this.angle));
    //rotateX(sin(this.angle));
    //rotateY(sin(this.angle));
    this.p5.box(this.w, this.h, 10);
    this.p5.pop();
    this.angle += this.rate;
    //translate(0, 0);
  }

}