import * as Tone from "tone";
import { markRaw } from "vue";
import Generator from "@/utilities/markov";
//import { InstrumentPart } from "./instrument";
import InstrumentPart from "@/vaporwave/instrument";

class Harmony extends InstrumentPart {
    constructor(bars, bank, buffers = null, parent = null) {

        super(bars, `bank_${bank}`, buffers);
        // converted midis
        this.midiA = require('@/assets/json/ASoCiAl MeDiA NeOn PoP/Harmony A.json');
        this.midiB = require('@/assets/json/ASoCiAl MeDiA NeOn PoP/Harmony B.json');
        this.midiC = require('@/assets/json/ASoCiAl MeDiA NeOn PoP/Harmony C.json');

        this.sampler = new Tone.Sampler({
            urls: {
                C3: buffers.get("C3"),
                C4: buffers.get("C4"),
                C5: buffers.get("C5"),
            },
            baseUrl: `/audio/vaporwave/bank_${bank}/`,
            volume: this.volume,
            onload: () => {
                console.log("harmony loaded");
            },
        })

        if (parent === null) {
            this.sampler.toDestination();
        } else {
            const panner = new Tone.Panner({
                pan: 0.5,
                channelCount: 2
            }).connect(parent);
            this.sampler.connect(panner);
        }

        const reverb = new Tone.Reverb({
            decay: 10,
            preDelay: 0.5,
            wet: 0.5,
        }).toDestination();
        //this.sampler.connect(reverb);

        let partA = this.partFromMidi(this.midiA);
        let partB = this.partFromMidi(this.midiB);
        let partC = this.partFromMidi(this.midiC);

        let aIncludes = [1, 5];
        let bIncludes = [2, 9];
        let cIncludes = [3, 6, 10];

        for (let i = 0; i < 12; i++) {
            if (aIncludes.includes(i)) {
                let p = this.copyPart(partA, bars * i);
                this.parts.push(p);
            } else if (bIncludes.includes(i)) {
                let p = this.copyPart(partB, bars * i);
                this.parts.push(p);
            } else if (cIncludes.includes(i)) {
                let p = this.copyPart(partC, bars * i);
                this.parts.push(p);
            }
        }

        this.populateSequence();
    }

}

export default Harmony;