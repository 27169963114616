import Track from "@/classes/track";
import Drums from "@/vaporwave/albums/bezos/4/drums";
import Bass from "@/vaporwave/albums/bezos/4/bass";
import Lead from "@/vaporwave/albums/bezos/4/lead";
import FX from "@/vaporwave/albums/bezos/4/fx";
import Harmony from "@/vaporwave/albums/bezos/4/harmony";
import Pluck from "@/vaporwave/albums/bezos/4/pluck";
import * as Tone from "tone";
import Loops from "@/vaporwave/albums/bezos/4/loops";

class ROMDealer extends Track {

    constructor(kit, bank) {
        let k = Math.floor(Math.random() * 30) + 1;
        super(kit, k, 'bezos4');
        this.bpm = 71;
    }

    load(parent = null) {

        if (parent) {
            let oldParent = parent;
            //const limiter = new Tone.Limiter(-10).toDestination();
            //limiter.connect(parent);
            const bit = new Tone.BitCrusher(4).toDestination()
            //parent = limiter;
            //oldParent.connect(parent);
        }

        console.log(`pluck buffer loading state ${this.pluckBuffers.loaded}`);
        console.log(`drum buffer loading state ${this.drumBuffers.loaded}`);

        Tone.Transport.bpm.value = this.bpm;
        console.log(`transport bpm ${Tone.Transport.bpm.value}`);
        this.drums = new Drums(4, this.kit, null, this.drumBuffers, parent);
        this.bass = new Bass(4, this.bank, this.bassBuffers, parent);
        this.lead = new Lead(2, this.bank, this.leadBuffers, parent);
        this.fx = new FX(4, this.bank, this.fxBuffers, parent);
        this.harmony = new Harmony(4, this.bank, this.lead2Buffers, parent);
        this.pluck = new Pluck(4, this.bank, this.pluckBuffers, parent);
        this.instrumentParts = [this.lead, this.pluck, this.drums, this.bass, this.harmony];

        for (const loop of this.loopBuffers) {
            let newLoop = new Loops(4, loop, parent);
            this.loops.push(newLoop);
        }

    }

}

export default ROMDealer;