import * as Tone from "tone";
import { markRaw } from "vue";
import Generator from "@/utilities/markov";
//import { InstrumentPart } from "./instrument";
import InstrumentPart from "@/vaporwave/instrument";

class Harmony extends InstrumentPart {
    constructor(bars, bank, buffers = null, parent = null) {

        super(bars, `bank_${bank}`, buffers);
        // converted midis
        this.bars = bars;

        this.midiA = require('@/assets/json/Gradient-Tripping/Harmony_A.json');
        this.midiB = require('@/assets/json/Gradient-Tripping/Harmony_B.json');
        //this.midiC = require('@/assets/json/Gradient-Tripping/RC5_Chords.json');

        this.sampler = new Tone.Sampler({
            urls: {
                C3: buffers.get("C3"),
                C4: buffers.get("C4"),
                C5: buffers.get("C5"),
            },
            volume: this.volume,
            onload: () => {
                console.log("harmony loaded yesss");
                console.log(`harmony loaded buffer ok c3 ${buffers.get("C3").loaded}`);
            },
            onError: () => {
                console.log("harmony loaded error");
            }
        })

        if (parent === null) {
            this.sampler.toDestination();
        } else {
            const panner = new Tone.Panner({
                pan: -0.5,
                channelCount: 2
            }).connect(parent);
            this.sampler.connect(panner);
        }

        let partA = this.partFromMidi(this.midiA);
        let partB = this.partFromMidi(this.midiB);

        this.useOdds = false;

        let aIncludes = [0, 4, 5];
        let bIncludes = [2, 6, 8];
        let odds = [60, 20];
        for (let i = 0; i < 9; i++) {
            if (this.useOdds) {
                this.partFromOdds(odds, i, [partA, partB]);
            } else {
                if (aIncludes.includes(i)) {
                    let p = this.copyPart(partA, bars * i);
                    this.parts.push(p);
                } else if (bIncludes.includes(i)) {
                    let p = this.copyPart(partB, bars * i);
                    this.parts.push(p);
                }
            }
        }

        this.populateSequence();
    }

}

export default Harmony;