import * as Tone from "tone";
import { markRaw } from "vue";
import Generator from "@/utilities/markov";
//import { InstrumentPart } from "./instrument";
import InstrumentPart from "@/vaporwave/instrument";

class Lead extends InstrumentPart {
    constructor(bars, bank, buffers = null, parent = null) {

        super(bars, `bank_${bank}`, buffers);
        // converted midis
        this.midi = require('@/assets/json/Gradient-Tripping/Lead.json');
        this.bars = bars;

        let source = buffers === null ? "lead.wav" : buffers.get("C3");;
        console.log("lead source: " + source);

        console.log(`lead buffer c3 ${buffers.get("C3").loaded}`);

        this.sampler = new Tone.Sampler({
            urls: {
                C3: buffers.get("C3"),
                C4: buffers.get("C4"),
                C5: buffers.get("C5"),
            },
            baseUrl: `/audio/vaporwave/bank_${bank}/`,
            volume: this.volume,
            onload: () => {
                console.log("lead loaded b1");
                console.log(`lead loaded ok buffer c3 ${buffers.get("C3").loaded}`);
            },
            onerror: (error) => {
                console.log("lead error");
            }
        })

        if (parent === null) {
            this.sampler.toDestination();
        } else {
            const panner = new Tone.Panner({
                pan: 0.3,
                channelCount: 2
            }).connect(parent);
            this.sampler.connect(panner);
        }

        let part = this.partFromMidi(this.midi);
        let markovPart = this.markovPart(part, 0);

        let includes = [2];
        let markovIncludes = [6, 7, 8];
        let odds = [20, 60];
        this.useOdds = false

        for (let i = 0; i < 9; i++) {
            let r = (Math.floor(Math.random() * 100));

            if (this.useOdds) {
                this.partFromOdds(odds, i, [part], markovPart);
            } else {

                if (includes.includes(i)) {
                    let p = this.copyPart(part, bars * i);
                    this.parts.push(p);
                } else if (markovIncludes.includes(i)) {
                    let p = this.copyPart(markovPart, bars * i);
                    this.parts.push(p);
                }

            }

        }

        this.populateSequence();

    }

    makeRandomPart(start, length) {

        let part = [];

        let beat = 0;
        let bar = 0;
        let sixteenth = 0;
        let sequence = ["C1", "E1", "F1", "G1"];
        let currentSequencePosition = 0;

        for (var x = start; x < start + length; x += 1) {
            bar = x;
            for (var y = 0; y < 4; y += 1) {
                beat = y;
                sixteenth = 0;
                let newTime = `${bar}:${beat}:${sixteenth}`
                //let ticks = Tone.time(newTime).toTicks();

                part.push({
                    time: newTime,
                    note: sequence[currentSequencePosition],
                    duration: "16n",
                    velocity: 1,
                    ticks: 0 // fix this
                });

                if (currentSequencePosition < sequence.length - 1) {
                    currentSequencePosition++;
                } else {
                    currentSequencePosition = 0;
                }

                for (var z = 0; z < 4; z += 1) {
                    sixteenth = z;

                    // skip some sixteenths
                    if (Math.floor(Math.random() * 5) !== 1) {
                        continue;
                    }

                    let samples = ["C3", "E3", "F3", "G3"]
                    let randomLead2 = samples[Math.floor(Math.random() * samples.length)];
                    part.push({
                        time: `${bar}:${beat}:${sixteenth}`,
                        note: randomLead2,
                        duration: "2n",
                        velocity: 1,
                        ticks: 0 // fix this
                    });
                }
            }

            beat = 0;
            sixteenth = 0;
        }

        return part;
    }
}

export default Lead;