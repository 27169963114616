import * as Tone from "tone";
import { markRaw } from "vue";
import { Note, Scale, Chord, ChordType, Key, Progression } from "@tonaljs/tonal";
import { note, interval } from "@tonaljs/core";
import Generator from "@/utilities/markov";

export default class LoopInstrumentPart {

    // takes bars - length in bars for instument
    // bank - location of samples (bank_1 or kit_1 etc)
    constructor(bars, buffers = null) {
        this.player;
        this.sequence = [];
        this.parts = [];
        this.bars = bars;
    }

    populateSequence() {
        for (var i = 0; i < this.parts.length; i++) {
            let part = this.parts[i];

            for (var j = 0; j < part.length; j++) {
                this.sequence.push(part[j]);
            }
        }

    }

    triggerSample(currentPosition, time, transpose = null) {
        let bar = parseInt(currentPosition[0]);
        let beat = parseInt(currentPosition[1]);
        //let sixteenth = currentPosition[2].split(`.`)[0];
        let sixteenth = Math.round(parseFloat(currentPosition[2]));
        if (sixteenth === 4) {
            beat++;
            sixteenth = 0;
            if (beat === 4) {
                beat = 0;
                bar++;
            }
        }

        for (var i = 0; i < this.sequence.length; i++) {
            let component = this.sequence[i];
            let componentStartPos = component.start.split(`:`);
            let testStartBar = componentStartPos[0];
            let testStartBeat = componentStartPos[1];
            let testStartSixteenth = componentStartPos[2];
            let componentStopPos = component.end.split(`:`);
            let testStopBar = componentStopPos[0];
            let testStopBeat = componentStopPos[1];
            let testStopSixteenth = componentStopPos[2];
            if (
                testStartBar === `${bar}` &&
                testStartBeat === `${beat}` &&
                testStartSixteenth === `${sixteenth}`
            ) {
                console.log(`trying to play player ${this.player}`);
                this.player.start(time);
            } else if (
                testStopBar === `${bar}` &&
                testStopBeat === `${beat}` &&
                testStopSixteenth === `${sixteenth}`
            ) {
                console.log("stop");
                this.player.stop();
            }
        }
    }

    transposeNote(note, by) {
        return Note.transpose(note, by);
    }

}