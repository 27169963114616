<template>
  <div class="wrapper">
    <nav class="navbar" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <a class="navbar-item" href="/">
          <img src="/images/rc_logo.png" width="120" height="68" />
        </a>
        <a
          role="button"
          class="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbarBasicExample" class="navbar-menu">
        <div class="navbar-start">
          <a class="navbar-item" href="/collection/1">Your Collection</a>
        </div>
        <div class="navbar-end">
          <a class="navbar-item" href="/about">About</a>
          <a class="navbar-item" href="/faq">FAQ</a>
          <a class="navbar-item">
            <Metamask />
          </a>
        </div>
      </div>
    </nav>

    <!--<Token />-->
    <router-view />
  </div>
</template>


<script>
import Metamask from "./components/Metamask.vue";

document.addEventListener("DOMContentLoaded", () => {
  // Get all "navbar-burger" elements
  const $navbarBurgers = Array.prototype.slice.call(
    document.querySelectorAll(".navbar-burger"),
    0
  );

  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {
    // Add a click event on each of them
    $navbarBurgers.forEach((el) => {
      el.addEventListener("click", () => {
        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle("is-active");
        $target.classList.toggle("is-active");
      });
    });
  }
});

export default {
  name: "App",
  components: {
    Metamask,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Faster+One&display=swap");
</style>

