import * as Tone from "tone";
import { markRaw } from "vue";
import Generator from "@/utilities/markov";
import LoopInstrumentPart from "@/vaporwave/loopInstrument";

class Loops extends LoopInstrumentPart {
    constructor(bars, buffer, parent = null) {

        super(bars, buffer);
        console.log(`buffer is ${buffer}!`);

        this.player = new Tone.Player({
            url: buffer
        })
        this.player.loop = true;
        //this.player.volume.value = -2;

        if (parent === null) {
            this.player.toDestination();
        } else {
            //const panner = new Tone.Panner(-0.1).connect(parent);
            //this.player.connect(panner);
            const pitchShift = new Tone.PitchShift({
                pitch: -12,
                wet: 0.0
            }).connect(parent);
            this.player.connect(pitchShift);
        }

        let a = [
            { start: "8:0:0", end: "12:0:0" },
            { start: "20:0:0", end: "24:0:0" },
            { start: "28:0:0", end: "32:0:0" }
        ]

        this.parts = [a];
        this.populateSequence();
    }
}

export default Loops;